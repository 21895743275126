import LoginForm from "../components/LoginForm";
import AuthLayout from "../../../view/layouts/AuthLayout";

const Login = () => {
  return (
    <div>
      <AuthLayout>
        <LoginForm />
      </AuthLayout>
    </div>
  );
};
export default Login;
