import DashboardLayout from "../../../view/layouts/DashboardLayout";

const Notification = () => {
  return (
    <DashboardLayout>
      <div></div>
    </DashboardLayout>
  );
};
export default Notification;
