import classes from "./style/Services.module.scss";
import ServiceCard from "../../../components/base/ServiceCard";
import { useState } from "react";
import TitleSection from "../../../components/core/TitleSection";
const Services = () => {
  const [serviceData] = useState([
    {
      title: "Startup Implementation",
      description: "Unleashing the Power of Software Solutions",
      items: [
        "Expert recommendations for unprecedented success",
        "From concept to completion: a seamless journey",
        "From minimum viable products to boundless possibilities",
      ],
      btnText: "Read More",
    },
    {
      title: "End-to-End Development",
      description: "Empowering Business Transformation",
      items: [
        "Requirements analysis: building the blueprint for success",
        "From code to creation: transforming ideas into powerful solutions",
        "Deployment and support: launching, maintaining, and evolving your solution",
      ],
      btnText: "Read More",
    },
    {
      title: "Legacy Application Modernization",
      description: "Enhanced Performance and Scalabilityn",
      items: [
        "Improved User Experience",
        "Cost Optimization",
        "Change Management",
      ],
      btnText: "Read More",
    },
    {
      title: "Architecture Consulting",
      description: "Streamline Your Software Solutions",
      items: [
        "Understanding Client Requirements",
        "Designing a Robust Software Architecture",
        "Architecture Assessments and Audits",
      ],
      btnText: "Read More",
    },
  ]);
  return (
    <div className={classes.serviceSection}>
      <TitleSection>
        <h1>
          <span>Services</span>
          on the
          <br /> WingsTech
        </h1>
      </TitleSection>
      <div className={classes.serviceFrame}>
        {serviceData.map((item, index) => {
          return (
            <div key={index} className={classes.serviceCardItem}>
              <ServiceCard
                title={item.title}
                description={item.description}
                items={item.items}
                btnText={item.btnText}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Services;
