import DashboardLayout from "../../../view/layouts/DashboardLayout";
import classes from "./style/Dashboard.module.scss";
import Products from "../components/Products";
import Services from "../components/Services";
import Welcome from "../components/Welcome";
import Notification from "../components/Notification";
import { workspaceActions } from "../../workspace/store/workspace-slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(workspaceActions.setSelectedWorkspace(null));
  }, []);
  return (
    <DashboardLayout>
      <div className={classes.dashboard}>
        <div className={classes.welcomeSection}>
          <div className={classes.welcome}>
            <Welcome />
          </div>
          <div className={classes.notification}>
            <Notification />
          </div>
        </div>
        <div className={classes.products}>
          <Products />
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Dashboard;
