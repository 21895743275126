import { Route, Routes } from "react-router-dom";
import YourLicense from "../pages/YourLicense";

const YourLicenseRouter = () => {
  return (
    <Routes>
      <Route
        path="/:workspaceName/:workspaceId"
        exact
        element={<YourLicense />}
      />
      <Route path="" exact element={<YourLicense />} />
    </Routes>
  );
};

export default YourLicenseRouter;
