import classes from "./style/YourLicense.module.scss";
import DashboardLayout from "../../../view/layouts/DashboardLayout";
import LicenseManagment from "../components/LicenseManagment";
import TitleSection from "../../../components/core/TitleSection";
import { useParams } from "react-router-dom";
const YourLicense = () => {
  const params = useParams();
  const workspaceId = params.workspaceId;
  const workspaceName = params.workspaceName;
  return (
    <DashboardLayout>
      <div className={classes.licenseBox}>
        <TitleSection>
          <h1>
            <span>subscription</span> & <span>Required</span>
            <br /> <span>fields</span>lists
          </h1>
        </TitleSection>
      </div>
      <div className={classes.cardsBox}>
        {workspaceId && workspaceName && <LicenseManagment />}
      </div>
    </DashboardLayout>
  );
};
export default YourLicense;
