/*
 <TextButton
    size="sm"
    title="Lrarn More"
    color='secondary'
    hasRightIcon={true}
    hasLeftIcon={false}
  ></TextButton>
*/
import { ArrowRight } from "iconsax-react";
import { useEffect, useState } from "react";
const TextButton = (props) => {
  const [name, setName] = useState("my-btn ");
  useEffect(() => {
    classNameComputed();
  }, []);
  const classNameComputed = () => {
    let tempName = name;
    if (props.size === "sm") {
      tempName = tempName + " small-text-btn ";
    } else if (props.size === "lg") {
      tempName = tempName + " larg-text-btn ";
    } else {
      tempName = tempName + " medium-text-btn ";
    }
    if (props.color === "secondary") {
      tempName = tempName + " secondary-text-btn ";
    } else if (props.color === "tertiary") {
      tempName = tempName + " tertiary-text-btn ";
    } else {
      tempName = tempName + " primary-text-btn ";
    }
    setName(tempName);
  };
  return (
    <div className={name} onClick={props.onClickHandler}>
      {props.hasLeftIcon && props.icon ? (
        props.icon
      ) : props.hasLeftIcon ? (
        <ArrowRight></ArrowRight>
      ) : (
        ""
      )}
      <span> {props.title}</span>
      {props.hasRightIcon && props.icon ? (
        props.icon
      ) : props.hasRightIcon ? (
        <ArrowRight></ArrowRight>
      ) : (
        ""
      )}
    </div>
  );
};
export default TextButton;
