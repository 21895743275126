import classes from "./style/Products.module.scss";
import ProductCard from "../../../components/base/ProductCard";
import { useEffect, useState } from "react";
import { Code, Menu } from "iconsax-react";
import TitleSection from "../../../components/core/TitleSection";
import { productActions } from "../../product/store/product-slice";
import { errorActions } from "../../../store/error-slice";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
const ProductRepository = () => RepoFactory.get("product");

const Products = () => {
  const productList = useSelector((state) => state.product.productList);
  const hasError = useSelector((state) => state.error.hasError);
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const dispatch = useDispatch();
  const [hasCodeInOneLisence, setHasCodeInOneLisence] = useState(false);
  const [hasCharmDBLisence, setHasCharmDBLisence] = useState(false);
  const charmdbLink =
    process.env.REACT_APP_CHARM_DB_DOMAIN +
    `?selectedWorkspaceId=${localStorage.getItem(
      "selectedWorkspaceId"
    )}&idToken=${localStorage.getItem(
      "idToken"
    )}&selectedLicensedWorkspace=${localStorage.getItem(
      "selectedLicensedWorkspace"
    )}`;
  const codeInOneLink =
    process.env.REACT_APP_CODE_IN_ONE_DOMAIN +
    `?selectedWorkspaceId=${localStorage.getItem(
      "selectedWorkspaceId"
    )}&idToken=${localStorage.getItem(
      "idToken"
    )}&selectedLicensedWorkspace=${localStorage.getItem(
      "selectedLicensedWorkspace"
    )}`;
  const checkLicense = () => {
    setHasCharmDBLisence(false);
    setHasCodeInOneLisence(false);
    console.log(selectedLicensedWorkspace);

    if (
      selectedLicensedWorkspace &&
      selectedLicensedWorkspace.subscriptionDtos?.length
    ) {
      selectedLicensedWorkspace.subscriptionDtos.forEach((element) => {
        if (element.productname === "codeinone") {
          setHasCodeInOneLisence(true);
        } else if (element.productname === "charmdb") {
          setHasCharmDBLisence(true);
        }
      });
    }
  };
  const codeIcon = () => {
    return <Code className={classes.icon} />;
  };
  const dbIcon = () => {
    return <Menu className={classes.icon} />;
  };
  const getProductList = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    ProductRepository()
      .getProductList()
      .then((res) => {
        dispatch(productActions.setProductList(res.data.value.products));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    setHasCharmDBLisence(false);
    setHasCodeInOneLisence(false);
    checkLicense();
  }, [
    selectedLicensedWorkspace,
    selectedLicensedWorkspace?.id,
    localStorage.getItem("selectedLicensedWorkspace"),
  ]);
  useEffect(() => {
    if (!productList.length) {
      setHasCharmDBLisence(false);
      setHasCodeInOneLisence(false);
      getProductList();
    }
  }, [productList]);
  return (
    <div className={classes.productSection}>
      <TitleSection>
        <h1>
          <span>Products</span>
          on the
          <br />
          WingsTech
        </h1>
      </TitleSection>
      <div className={classes.productCardFrame}>
        <div className={classes.productCardItem}>
          <ProductCard
            icon={codeIcon()}
            title="Code in One"
            items={[
              "Say goodbye to manual backend setup and maintenance",
              "Revolutionize software production and reduce costs",
              "Experience the simplicity of domain driven design and microservice architecture",
            ]}
            btnText={
              hasCodeInOneLisence
                ? "Access it"
                : selectedLicensedWorkspace?.role === "Owner" ||
                  !selectedLicensedWorkspace
                ? "Try it"
                : "Read More"
            }
            hasLisence={hasCodeInOneLisence}
            link={
              hasCodeInOneLisence
                ? codeInOneLink
                : selectedLicensedWorkspace?.role === "Owner" ||
                  !selectedLicensedWorkspace
                ? `/workspace/productId/1`
                : "/"
            }
          />
        </div>
        <div className={classes.productCardItem}>
          <ProductCard
            icon={dbIcon()}
            title="CharmDb"
            items={[
              "Visual, user-friendly database design",
              "Flexible Output Generation",
              "Enhanced Sharing Features",
            ]}
            btnText={
              hasCharmDBLisence
                ? "Access it"
                : selectedLicensedWorkspace?.role === "Owner" ||
                  !selectedLicensedWorkspace
                ? "Try it"
                : "Read More"
            }
            hasLisence={hasCharmDBLisence}
            link={
              hasCharmDBLisence
                ? charmdbLink
                : selectedLicensedWorkspace?.role === "Owner" ||
                  !selectedLicensedWorkspace
                ? `/workspace/productId/2`
                : "/"
            }
          />
        </div>
        {hasError && <ErrorHandling />}
      </div>
    </div>
  );
};
export default Products;
