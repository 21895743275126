import classes from "./style/HeaderDropDown.module.scss";
import { ArrowDown2, ArrowUp2, Add, Logout } from "iconsax-react";
import { authActions } from "../../modules/auth/store/auth-slice";
import { workspaceActions } from "../../modules/workspace/store/workspace-slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const HeaderDropDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceList = useSelector((state) => state.workspace.workspaceList);
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const [otherWorkspace, setOtherWorkspace] = useState([]);
  const [listFlag, setListFlag] = useState(false);
  const exitHandler = () => {
    return props.exitAction(false);
  };
  const changeWorkSpace = (workspace) => {
    localStorage.setItem(
      "selectedLicensedWorkspace",
      JSON.stringify(workspace)
    );
    localStorage.setItem("selectedWorkspaceId", JSON.stringify(workspace?.id));
    dispatch(workspaceActions.setSelectedLicensedWorkspace(workspace));
    let path = window.location.pathname;
    if (path.includes("your-license")) {
      navigate(`/your-license/${workspace.name}/${workspace.id}`);
    }
    setListFlag(false);
    exitHandler();
  };

  const newWorkSpace = () => {
    localStorage.setItem("selectedLicensedWorkspace", null);
    localStorage.setItem("selectedWorkspaceId", null);
    dispatch(workspaceActions.setSelectedLicensedWorkspace(null));
    setListFlag(false);
    exitHandler();
  };
  const logout = () => {
    localStorage.clear();
    dispatch(workspaceActions.logout());
    dispatch(authActions.logOut());
  };
  useEffect(() => {
    let array = [];
    if (selectedLicensedWorkspace) {
      array = workspaceList?.filter(
        (item) => item?.id !== selectedLicensedWorkspace?.id
      );
    } else {
      array = workspaceList;
    }
    setOtherWorkspace(array);
  }, [selectedLicensedWorkspace]);
  return (
    <div className={classes.dropDownConataner}>
      <div
        className={classes.menuItem}
        onClick={(e) => {
          if (otherWorkspace.length > 0) setListFlag(!listFlag);
        }}
      >
        <div className={classes.itemBox}>
          <div className={classes.itemIcon}>
            <span className={classes.textIcon}>
              {selectedLicensedWorkspace
                ? selectedLicensedWorkspace.name[0]
                : "N"}
            </span>
          </div>
          <span className={classes.itemText}>
            {selectedLicensedWorkspace
              ? selectedLicensedWorkspace.name
              : "Your Workspace"}
          </span>
        </div>

        {listFlag && otherWorkspace.length > 0 && (
          <ArrowUp2 className={classes.arrowIcon} />
        )}
        {!listFlag && otherWorkspace.length > 0 && (
          <ArrowDown2 className={classes.arrowIcon} />
        )}
      </div>
      {listFlag && otherWorkspace && (
        <div className={classes.workspaceList}>
          {otherWorkspace?.map((item, index) => {
            return (
              <div
                key={index + Math.random()}
                className={classes.workspaceItem}
                onClick={(e) => changeWorkSpace(item)}
              >
                <div className={classes.workspaceItemIcon}>
                  <span className={classes.workspaceItemTextIcon}>
                    {item.name[0]}
                  </span>
                </div>
                <span className={classes.workspaceItemText}>{item.name}</span>
              </div>
            );
          })}
        </div>
      )}
      {selectedLicensedWorkspace && (
        <div className={classes.menuItem} onClick={(e) => newWorkSpace()}>
          <div className={classes.itemBox}>
            <div className={classes.itemIcon}>
              <Add className={classes.icon} />
            </div>
            <span className={classes.itemText}>New Workspace</span>
          </div>
        </div>
      )}
      {window.innerWidth > 834 && (
        <div className={classes.menuItem} onClick={(e) => logout()}>
          <div className={classes.itemBox}>
            <div className={classes.itemIcon}>
              <Logout className={classes.icon} />
            </div>
            <span className={classes.itemText}>Log Out</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default HeaderDropDown;
