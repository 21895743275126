import classes from "./style/Input.module.scss";
import TextButton from "./TextButton";
import { Edit, Save2, Eye, EyeSlash } from "iconsax-react";
import { useState, useEffect } from "react";
const Input = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const [editFlag, setEditFlag] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (props.readOnly === true || props.status === "edit") {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [props.status]);
  useEffect(() => {
    if (props.success) {
      setEditFlag(true);
    }
  }, [props.success]);
  const editButtonHandler = () => {
    setReadOnly(false);
    setEditFlag(false);
  };
  const EditIcon = () => {
    return <Edit />;
  };
  const SaveIcon = () => {
    return <Save2 />;
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className={classes.inputContainer}>
      {props.label && (
        <div className={classes.label}>
          <label className={classes.labelText}>{props.label}</label>
          {props.status === "edit" && editFlag === true && (
            <TextButton
              size="lg"
              title="Edit"
              color="secondary"
              hasLeftIcon={true}
              icon={EditIcon()}
              onClickHandler={(e) => editButtonHandler()}
            />
          )}
          {props.status === "edit" && editFlag === false && (
            <TextButton
              size="lg"
              title="Save"
              color="primary"
              hasLeftIcon={true}
              icon={SaveIcon()}
              onClickHandler={props.saveHandler}
            />
          )}
        </div>
      )}
      <div className={props.circleShape ? classes.digitItem : classes.input}>
        {!props.circleShape && props.icon && (
          <div className={classes.icon}>{props.icon}</div>
        )}
        <input
          className={classes.inputSection}
          type={showPassword ? "text" : props.type}
          placeholder={props.placeholder}
          id={props.id ? props.id : Math.random()}
          name={props.name}
          max={props.maxLength}
          onChange={props.handleChange}
          onKeyDown={props.keyHandler}
          required
          pattern={props.pattern}
          value={props.value}
          readOnly={readOnly}
        />

        {props.type === "password" && (
          <button
            onClick={handleTogglePassword}
            className={`${classes.eyeButton}`}
          >
            {!showPassword ? (
              <EyeSlash className={`${classes.eyeIcon}`} />
            ) : (
              <Eye className={`${classes.eyeIcon}`} />
            )}
          </button>
        )}
      </div>
      {props.invalid && (
        <div className={classes.errorBox}>
          <span className={classes.errorMsg}>{props.invalidMsg}</span>
        </div>
      )}
    </div>
  );
};
export default Input;
