import DashboardLayout from "../../../view/layouts/DashboardLayout";
import classes from "./style/Workspace.module.scss";
import WorkspaceForm from "../components/WorkspaceForm";
import WorkspaceList from "../components/WorkspaceList";
import TitleSection from "../../../components/core/TitleSection";
import Pricing from "../../subscriptionPlans/components/Pricing";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect, useState } from "react";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const WorkspaceRepository = () => RepoFactory.get("workspace");
const Workspace = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const selectedWorkspace = useSelector(
    (state) => state.workspace.selectedWorkspace
  );
  const workspaceListHaveLicenseAndShowInHeader = useSelector(
    (state) => state.workspace.workspaceList
  );
  const [openWorkspaceForm, setOpenWorkspaceForm] = useState(true);
  const [openPlanList, setOpenPlanList] = useState(false);
  const [workspacesList, setWorkspacesList] = useState([]);
  const [workspaceStatus, setWorkspaceStatus] = useState("create");
  const productId = params.Id;
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setWorkspaceStatus(params.get("status"));
    if (selectedLicensedWorkspace) {
      setOpenWorkspaceForm(false);
    } else {
      setOpenWorkspaceForm(true);
    }
  }, [selectedLicensedWorkspace]);
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setWorkspaceStatus(params.get("status"));
    if (workspaceStatus === "create") {
      GetOwnerWorkspaces();
    }
  }, []);
  const GetOwnerWorkspaces = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    WorkspaceRepository()
      .GetOwnerWorkspaces()
      .then((res) => {
        let array = [];
        res.data.value.workSpaces.forEach((element) => {
          let hasLisenced = false;
          workspaceListHaveLicenseAndShowInHeader.forEach(
            (hasLicencedWorkspace, index) => {
              if (hasLicencedWorkspace.id === element.id) {
                hasLisenced = true;
                let hasCodeInOne = false;
                let hasCharmDb = false;
                hasLicencedWorkspace.subscriptionDtos.forEach(
                  (subscriptionPlan, subscriptionPlanIndex) => {
                    if (subscriptionPlan.productId === 1) {
                      hasCodeInOne = true;
                    } else if (subscriptionPlan.productId === 2) {
                      hasCharmDb = true;
                    }
                    if (
                      subscriptionPlanIndex ===
                      hasLicencedWorkspace.subscriptionDtos.length - 1
                    ) {
                      array.push({
                        ...element,
                        title: element.name,
                        hasCodeInOne,
                        hasCharmDb,
                      });
                    }
                  }
                );
              }
              if (
                index === workspaceListHaveLicenseAndShowInHeader.length - 1 &&
                !hasLisenced
              ) {
                array.push({ ...element, title: element.name });
              }
            }
          );
        });
        setWorkspacesList(array);
        if (res.data.value.workSpaces.length === 0) {
          changeWorkspaceForm(true);
        }
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const changeWorkspaceForm = (value) => {
    setOpenWorkspaceForm(value);
    if (!value) {
      GetOwnerWorkspaces();
    }
  };
  const openPlanListHandler = () => {
    setOpenPlanList(true);
  };
  const backToWorkSpaceList = () => {
    setOpenPlanList(false);
  };
  const setPageTitle = () => {
    if (openWorkspaceForm) {
      return (
        <h1>
          Lets Create a <span>New </span>
          <br /> Workspace
        </h1>
      );
    } else {
      return (
        <h1>
          Complete <span>{productId === "1" ? "Code in One" : "Charm DB"}</span>{" "}
          subscription on
          <span>
            {" "}
            {selectedWorkspace
              ? selectedWorkspace?.name
              : selectedLicensedWorkspace?.name}
          </span>
        </h1>
      );
    }
  };
  return (
    <DashboardLayout>
      <div className={classes.checkout}>
        {workspaceStatus !== "update" && (
          <div className={classes.workspace}>
            <TitleSection>{setPageTitle()}</TitleSection>
            {openWorkspaceForm ? (
              <WorkspaceForm handleChange={changeWorkspaceForm} />
            ) : openPlanList ? (
              <Pricing backToWorkSpaceList={(e) => backToWorkSpaceList()} />
            ) : (
              <WorkspaceList
                handleChange={changeWorkspaceForm}
                workspacesList={workspacesList}
                openPlanListHandler={(e) => openPlanListHandler()}
                productId={productId}
              />
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
export default Workspace;
