import AuthLayout from "../../../view/layouts/AuthLayout";
import ForgetPasswordForm from "../components/ForgetPasswordFrom";
import StepTwoForgetPasswordFrom from "../components/StepTwoForgetPasswordForm";
import StepThreeForgetPasswordForm from "../components/StepThreeForgetPasswordForm";
import { useState } from "react";

const ForgetPassword = () => {
  const [stepNumber, setStepNumber] = useState(1);
  const [userName, setUserName] = useState("");

  const changeUserName = (value) => {
    setUserName(value);
  };
  const changeStep = (value) => {
    setStepNumber(value);
  };
  return (
    <div>
      <AuthLayout>
        {stepNumber === 1 && (
          <ForgetPasswordForm
            handleChange={changeStep}
            changeUserName={changeUserName}
          />
        )}
        {stepNumber === 2 && (
          <StepTwoForgetPasswordFrom
            handleChange={changeStep}
            userName={userName}
          />
        )}
        {stepNumber === 3 && <StepThreeForgetPasswordForm />}
      </AuthLayout>
    </div>
  );
};
export default ForgetPassword;
