import classes from "./style/Support.module.scss";
import DashboardLayout from "../../../view/layouts/DashboardLayout";
import Contact from "../components/Contact";
import Ticket from "../components/Ticket";
import BaseButton from "../../../components/base/BaseButton";
import FAQ from "../../../components/base/FAQ";
import Highlight from "../../../assets/image/icon/HighlightBlack.svg";
const Support = () => {
  return (
    <DashboardLayout>
      <div className={classes.supportContainer}>
        <div className={classes.supportBox}>
          <div className={classes.supportBoxChild}>
            <Contact />
          </div>
          <div className={classes.supportBoxChild}>
            <Ticket />
          </div>
        </div>
        <div className={classes.infoBox}>
          <span className={classes.infoText}>
            In person and on demand Meet us on our home turf in Sydney,
            Australia in November for our biggest service management event of
            the year. In person and on demand Meet us on our home turf in
            Sydney, Australia in November for our biggest service management
            event of the year.In person and on demand Meet us on our home turf
            in Sydney, Australia in November for our biggest service management
            event of the year.
          </span>
          <span className={classes.infoText}>
            In person and on demand Meet us on our home turf in Sydney,
            Australia in November for our biggest service management event of
            the year. In person and on demand Meet us on our home turf in
            Sydney, Australia in November for our biggest service management
            event of the year.In person and on demand Meet us on our home turf
            in Sydney, Australia in November for our biggest service management
            event of the year.
          </span>
          <div className={classes.faqBox}>
            <div className={classes.faqHeader}>
              <div className={classes.headerTitle}>
                <div className={classes.faqTitle}>
                  <b className={classes.colorTitleText}>FAQ</b>
                  <div className={classes.HighlightImage}>
                    <img src={Highlight} alt="Highlight"></img>
                  </div>
                </div>
                <strong className={classes.titleText}>On The WingsTech</strong>

                <span className={classes.captionText}>
                  In person and on demand Meet us on our home turf in Sydney,
                  Australia in November for our biggest service management{" "}
                </span>
              </div>
              <BaseButton
                size="lg"
                title="View All"
                color="secondary"
                hasRightIcon={true}
              />
            </div>
            <div className={classes.questionBox}>
              <div className={classes.FAQSection}>
                <FAQ
                  question="Will you price gouge if I need additional work?"
                  answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
                />
              </div>

              <div className={classes.FAQSection}>
                <FAQ
                  question="Will you price gouge if I need additional work?"
                  answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
                />
              </div>
              <div className={classes.FAQSection}>
                <FAQ
                  question="Will you price gouge if I need additional work?"
                  answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
                />
              </div>
              <div className={classes.FAQSection}>
                <FAQ
                  question="Will you price gouge if I need additional work?"
                  answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
                />
              </div>
              <div className={classes.FAQSection}>
                <FAQ
                  question="Will you price gouge if I need additional work?"
                  answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Support;
