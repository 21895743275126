import classes from "./style/MobileHeader.module.scss";
import { useDispatch } from "react-redux";
import { HambergerMenu } from "iconsax-react";
import { dashboardActions } from "../../modules/dashboard/store/dashboard-slice";
import Logo from "../../assets/image/logo/blueLogo.svg";
const MobileHeader = () => {
  const dispatch = useDispatch();
  const showMenuHandler = () => {
    dispatch(dashboardActions.setShowSideBar(true));
  };
  return (
    <div className={classes.MobileHeader}>
      <div className={classes.logo}>
        <img src={Logo} alt="Logo" />
      </div>
      <div className={classes.showMenuIcon} onClick={(e) => showMenuHandler()}>
        <HambergerMenu />
      </div>
    </div>
  );
};
export default MobileHeader;
