/**
 *  <ServiceCard
        title="Psychiatry online visit"
        description="Your journey begins with a thorough diagnosis and initial assessment."
        items={[
          "Customized drug treatments",
          "Comprehensive diagnosis of ADHD",
          "Comprehensive diagnosis of ADHD",
        ]}
        btnText="Read More"
      ></ServiceCard>
 */

import classes from "./style/ServiceCard.module.scss";
import BaseButton from "./BaseButton";
import check from "../../assets/image/icon/check.svg";
import { useNavigate } from "react-router-dom";

const ServiceCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.serviceCard}>
      <div className={classes.header}>
        <h1>{props.title}</h1>
      </div>
      <div className={classes.body}>
        <div className={classes.description}>
          <p>{props.description}</p>
        </div>
        <div className={classes.items}>
          {props.items.map((item, index) => {
            return (
              <div key={index} className={classes.item}>
                <div className={classes.icon}>
                  <img src={check} alt="check" />
                </div>
                <div className={classes.text}>
                  <span>{item}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.btn}>
          <BaseButton
            size="lg"
            title={props.btnText}
            color="secondary"
            hasRightIcon={true}
            onClickHandler={(e) => navigate("/services")}
          ></BaseButton>
        </div>
      </div>
    </div>
  );
};
export default ServiceCard;
