import classes from "./style/DashboardLayout.module.scss";
import Logo from "../../assets/image/logo/whiteLogo.svg";
import BaseButton from "../../components/base/BaseButton";
import Header from "../../components/core/Header";
import { Edit } from "iconsax-react";
import Menu from "../../components/core/Menu";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MobileHeader from "../../components/core/MobileHeader";
import { CloseCircle } from "iconsax-react";
import { dashboardActions } from "../../modules/dashboard/store/dashboard-slice";

const DashboardLayout = (props) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const showSideBar = useSelector((state) => state.dashboard.showSideBar);
  const dispatch = useDispatch();
  const hideMenuHandler = () => {
    dispatch(dashboardActions.setShowSideBar(false));
  };
  const EditIcon = () => {
    return <Edit />;
  };
  return (
    <div className={classes.dashboardLayout}>
      <div
        className={
          showSideBar && window.innerWidth < 834
            ? classes.mobileSidebar
            : classes.sidebar
        }
      >
        <div className={classes.logo}>
          <img src={Logo} alt="Logo" />
          <div
            className={classes.closeIcon}
            onClick={(e) => {
              hideMenuHandler();
            }}
          >
            <CloseCircle />
          </div>
        </div>
        <div className={classes.menuSection}>
          <div className={classes.profile}>
            <div className={classes.avatar}>
              <span className={classes.textIcon}>
                {userInfo ? userInfo.nickname[0] : "N"}
              </span>
            </div>

            <div className={classes.name}>
              <span> {userInfo ? userInfo.nickname : ""}</span>
            </div>
            <div className={classes.edit}>
              <BaseButton
                size="sm"
                title="Edit Profile"
                hasLeftIcon={true}
                icon={EditIcon()}
                onClickHandler={(e) => navigate("/profile")}
              ></BaseButton>
            </div>
          </div>
          <div className={classes.menuItem}>
            <Menu />
          </div>
        </div>
      </div>
      <div className={classes.bodySection}>
        {showSideBar && <div className={classes.fadeBackground}></div>}
        <div className={classes.headeer}>
          <Header />
        </div>
        <div className={classes.mobileHeadeer}>
          <MobileHeader />
        </div>
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
};
export default DashboardLayout;
