import classes from "./style/Ticket.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import Textarea from "../../../components/base/Textarea";
import { User, Sms, Message2 } from "iconsax-react";
const Ticket = () => {
  const UserIcon = () => {
    return <User />;
  };
  const EmailIcon = () => {
    return <Sms />;
  };
  const MessageIcon = () => {
    return <Message2 />;
  };
  return (
    <div className={classes.ticketBox}>
      <span className={classes.ticketTitle}>Send a Ticket</span>
      <Input
        placeholder="Enter Your Name"
        type="text"
        name="name"
        icon={UserIcon()}
      />
      <Input
        placeholder="Enter Your Email"
        type="email"
        name="email"
        icon={EmailIcon()}
      />
      <Textarea
        placeholder="Write Your Message"
        name="message"
        maxLength={1000}
        icon={MessageIcon()}
      />
      <BaseButton
        size="lg"
        title="Send Ticket"
        color="primary"
        hasBlock={true}
      />
    </div>
  );
};
export default Ticket;
