import classes from "./style/TableCard.module.scss";
const TableCard = (props) => {
  return (
    <>
      {props.data &&
        props.data.map((row, index) => {
          return (
            <div className={classes.cardContainer} key={index}>
              {row.map((cell, cellIndex) => {
                return (
                  <div className={classes.cardLayer} key={cellIndex}>
                    {cell.title && !cell.hideTitleInMobile && (
                      <span className={classes.titleText}>{cell.title}</span>
                    )}
                    <span className={classes.userDetial}>{cell.row}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
    </>
  );
};
export default TableCard;
