/*
<SelectInput label='Name' placeHolder='Select an item ...'
          options={
            [
              { value: 'one', title: 'Name1' },
              { value: '2', title: 'Name2' },
              { value: '3', title: 'Name3' },
            ]
          } />

*/
import classes from "./style/SelectInput.module.scss";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useState, useEffect, useRef } from "react";
import TextButton from "./TextButton";
import { Edit } from "iconsax-react";
const SelectInput = (props) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [inputData, setInputData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [availableOptions, setAvailableOptions] = useState();
  const [readOnly, setReadOnly] = useState(false);
  useEffect(() => {
    if (props.status === "editable") setReadOnly(true);
  }, [props.status]);
  useEffect(() => {
    if (props.value) setInputData(props.value?.title);
  }, [props.value]);
  useEffect(() => {
    if (props.selectedItem) {
      setSelectedItem(props.selectedItem);
      setInputData(props.selectedItem.title);
    }
    setAvailableOptions(props.options ? props.options : []);
  }, [props.options, props.selectedItem]);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  const changeShowOptionStatus = () => {
    if (!readOnly) {
      setShowOptions(!showOptions);
    }
  };
  const selectedItemHandler = (e, selectItem) => {
    setSelectedItem(selectItem);
    setInputData(selectItem ? selectItem.title : "");
    props.handleChange(selectItem);
    changeShowOptionStatus();
    let optionsTemp = props.options
      .filter((item) => item.value !== selectItem.value)
      .map((item) => {
        return item;
      });
    setAvailableOptions(optionsTemp);
  };

  const searchData = (e) => {
    setInputData(e.target.value);
    setSelectedItem(null);
    setShowOptions(true);
    let searchOptionsTemp = props.options
      ?.filter((item) => item.title.includes(e.target.value))
      .map((item) => {
        return item;
      });

    let availableOptionsTemp = searchOptionsTemp.map((item) => {
      let selectedItemFalg = false;
      if (selectedItem && selectedItem.value === item.value) {
        selectedItemFalg = true;
      }
      if (!selectedItemFalg) {
        return item;
      }
    });
    setAvailableOptions(availableOptionsTemp);
  };
  const EditIcon = () => {
    return <Edit />;
  };
  const editButtonHandler = () => {
    setReadOnly(false);
  };
  return (
    <div className={classes.selectedInput} ref={ref}>
      {props.label && (
        <div className={classes.label}>
          <label className={classes.labelText}>{props.label}</label>
          {props.status === "editable" && (
            <TextButton
              size="lg"
              title="Edit"
              color="secondary"
              hasLeftIcon={true}
              icon={EditIcon()}
              onClickHandler={(e) => editButtonHandler()}
            />
          )}
        </div>
      )}
      <div
        className={classes.selectInput}
        onClick={(e) => changeShowOptionStatus()}
      >
        <div className={classes.textSection}>
          {props.icon && <div className={classes.iputIcon}>{props.icon}</div>}
          <input
            value={inputData}
            className={classes.inputSection}
            placeholder={props.placeHolder}
            onChange={(e) => searchData(e)}
            readOnly={readOnly}
          />
        </div>
        <div className={classes.iconSection}>
          {!showOptions ? (
            <ArrowDown2 className={classes.icon} />
          ) : (
            <ArrowUp2 className={classes.icon} />
          )}
        </div>

        {showOptions && (
          <div className={classes.optionsBox}>
            {availableOptions?.length > 0 ? (
              availableOptions.map((item, index) => {
                return (
                  <div
                    onClick={(e) => selectedItemHandler(e, item)}
                    key={index}
                    className={`${classes.item} }`}
                  >
                    {props.icon && (
                      <div className={classes.optionIcon}>{props.icon}</div>
                    )}
                    <span>{item?.title}</span>
                  </div>
                );
              })
            ) : (
              <div>Data not found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default SelectInput;
