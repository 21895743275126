import classes from "./style/EmailNotification.module.scss";
import SettingCard from "./SettingCard";
const EmailNotification = () => {
  const cardAction = () => {};
  return (
    <div className={classes.notificationBox}>
      <div className={classes.headerBox}>
        <span className={classes.notificationHeader}>
          Email <span className={classes.colorText}>Notifications</span>
        </span>
      </div>

      <div className={classes.notificationCardBox}>
        <div className={classes.largScreen}>
          <SettingCard
            size={window.innerWidth > 834 ? "lg" : ""}
            title="Wingstech News1"
            description="You can invite up to 4 users in the form. so please input users email address."
            cardAction={(e) => cardAction()}
            checked={true}
          />
        </div>
        <div className={classes.smallScreen}>
          <SettingCard
            title="Wingstech News1"
            description="You can invite up to 4 users in the form. so please input users email address."
            cardAction={(e) => cardAction()}
            checked={true}
          />
        </div>

        <div className={classes.smallBox}>
          <SettingCard
            title="Wingstech News2"
            description="You can invite up to 4 users in the form. so please input users email address."
            checked={false}
          />
          <SettingCard
            title="Wingstech News3"
            description="You can invite up to 4 users in the form. so please input users email address."
            checked={true}
          />
        </div>
      </div>
    </div>
  );
};
export default EmailNotification;
