import { createSlice } from "@reduxjs/toolkit";

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    workspaceList: [],
    selectedWorkspace: null, // selected workspace in workspace page and user is ownerd
    selectedLicensedWorkspace: null, // selected workspace in header and this workspace have license and paln
  },
  reducers: {
    setSelectedLicensedWorkspace(state, actions) {
      state.selectedLicensedWorkspace = actions.payload;
    },
    setSelectedWorkspace(state, actions) {
      state.selectedWorkspace = actions.payload;
    },
    setWorkspaceList(state, actions) {
      state.workspaceList = actions.payload;
    },
    logout(state) {
      state.workspaceList = [];
      state.selectedWorkspace = null;
      state.selectedLicensedWorkspace = null;
    },
  },
});
export const workspaceActions = workspaceSlice.actions;
export default workspaceSlice.reducer;
