import { Route, Routes } from "react-router-dom";
import EmailSetting from "../pages/EmailSetting";
import PasswordSetting from "../pages/PasswordSetting";
import WorkspaceSetting from "../pages/WorkspaceSetting";
import AccountSetting from "../pages/AccountSetting";
const SettingRouter = () => {
  return (
    <Routes>
      <Route path="/email-notification" exact element={<EmailSetting />} />
      <Route path="/change-password" exact element={<PasswordSetting />} />
      <Route path="/edit-workspace" exact element={<WorkspaceSetting />} />
      <Route path="/remove-account" exact element={<AccountSetting />} />
    </Routes>
  );
};
export default SettingRouter;
