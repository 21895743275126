// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Workspace_checkout__1I5PT {
  display: flex;
  flex-direction: column;
  gap: 90px;
}

.Workspace_workspace__lB\\+TO {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/modules/workspace/pages/style/Workspace.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAEF","sourcesContent":[".checkout {\n  display: flex;\n  flex-direction: column;\n  gap: 90px;\n}\n.workspace {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkout": `Workspace_checkout__1I5PT`,
	"workspace": `Workspace_workspace__lB+TO`
};
export default ___CSS_LOADER_EXPORT___;
