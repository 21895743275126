import { Route, Routes } from "react-router-dom";
import Workspace from "../pages/Workspace";

const WorkspaceRouter = () => {
  return (
    <Routes>
      <Route path="/productId/:Id" exact element={<Workspace />} />
    </Routes>
  );
};

export default WorkspaceRouter;
