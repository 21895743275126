import AuthRouter from "../modules/auth/routes/AuthRouter";
import DashboardRouter from "../modules/dashboard/routes/DashboardRoutes";
import WorkspaceRouter from "../modules/workspace/routes/WorkspaceRouter";
import YourLicenseRouter from "../modules/yourLicense/routes/yourLicenseRouter";
import TeamManagmentRouter from "../modules/TeamManagement/routes/TeamManagementRouter";
import ProfileRouter from "../modules/profile/routes/ProfileRouter";
import SettingRouter from "../modules/setting/routes/SettingRouter";
import SupportRouter from "../modules/support/routes/SupportRouter";
import NotificationRouter from "../modules/notification/routes/NotificationRouter";
import ServicesRouter from "../modules/services/routes/ServicesRouter";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../modules/auth/store/auth-slice";
import { useCookies } from "react-cookie";
var { BrowserRouter, Routes, Route, Navigate } = require("react-router-dom");
const Router = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [isAuthanticated, setIsAuthanticated] = useState(
    cookies.access_token ? true : false
  );
  const userInfo = useSelector((state) => state.auth.userInfo);
  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      dispatch(authActions.setUserInfo(localStorage.getItem("idToken")));
      dispatch(authActions.setIsAuthenticated(true));
    } else if (!isAuthanticated) {
      removeCookie("access_token");
    }
  }, [dispatch]);
  useEffect(() => {
    setIsAuthanticated(cookies.access_token ? true : false);
  }, [cookies.access_token]);
  useLayoutEffect(() => {
    dispatch(authActions.setIsAuthenticated(isAuthanticated));
  }, [isAuthanticated, dispatch, userInfo, cookies]);
  const justLoginRoute = () => {
    return (
      <Routes>
        <Route exact path="/auth/*" element={<AuthRouter />} />
        <Route path="*" element={<Navigate replace to="/auth/login" />} />
      </Routes>
    );
  };
  const isAuthanticatedRoutes = () => {
    return (
      <Routes>
        <Route exact path="/" element={<DashboardRouter />} />
        <Route exact path="/workspace/*" element={<WorkspaceRouter />} />
        <Route exact path="/your-license/*" element={<YourLicenseRouter />} />
        <Route
          exact
          path="/team-management/*"
          element={<TeamManagmentRouter />}
        />
        <Route exact path="/profile/*" element={<ProfileRouter />} />
        <Route exact path="/setting/*" element={<SettingRouter />} />
        <Route exact path="/support/*" element={<SupportRouter />} />
        <Route exact path="/notification/*" element={<NotificationRouter />} />
        <Route exact path="/services/*" element={<ServicesRouter />} />
        <Route path="/auth/*" element={<Navigate replace to="/" />} />
        <Route path="*" element={() => <Navigate replace to="/" />} />
      </Routes>
    );
  };
  return (
    <BrowserRouter>
      {!isAuthanticated && justLoginRoute()}
      {isAuthanticated && isAuthanticatedRoutes()}
    </BrowserRouter>
  );
};
export default Router;
