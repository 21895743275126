import SettingPageLayout from "../components/SettingPageLayout";
import ChangePassword from "../components/ChangePassword";
const PasswordSetting = () => {
  return (
    <SettingPageLayout>
      <ChangePassword />
    </SettingPageLayout>
  );
};
export default PasswordSetting;
