/*
 <IconButton
    size="sm"
    color='secondary'
    icon={icon()}
  ></IconButton>
*/
import { useEffect, useState } from "react";
const IconButton = (props) => {
  const [name, setName] = useState("icon-btn");
  useEffect(() => {
    classNameComputed();
  }, []);
  const classNameComputed = () => {
    let tempName = name;
    if (props.size === "sm") {
      tempName = tempName + " small-btn ";
    } else if (props.size === "lg") {
      tempName = tempName + " larg-btn ";
    } else {
      tempName = tempName + " medium-btn ";
    }
    if (props.color === "secondary") {
      tempName = tempName + " secondary-btn ";
    } else if (props.color === "tertiary") {
      tempName = tempName + " tertiary-btn ";
    } else {
      tempName = tempName + " primary-btn ";
    }
    setName(tempName);
  };
  return (
    <div className={name} onClick={props.onClickHandler}>
      {props.icon}
    </div>
  );
};
export default IconButton;
