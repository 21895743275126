import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Dashboard />} />
    </Routes>
  );
};

export default DashboardRouter;
