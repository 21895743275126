import SettingPageLayout from "../components/SettingPageLayout";
import EditWorkspace from "../components/EditWorkspace";
const WorkspaceSetting = () => {
  return (
    <SettingPageLayout>
      <EditWorkspace />
    </SettingPageLayout>
  );
};
export default WorkspaceSetting;
