import classes from "./style/ToggleText.module.scss";
const ToggleText = (props) => {
  return (
    <div className={classes.toggleBox}>
      <input
        type="checkbox"
        id="toggle"
        className={classes.toggleCheckbox}
        onChange={props.changeHandler}
      />
      <label htmlFor="toggle" className={classes.toggleContainer}>
        {props.items.map((item, index) => {
          return (
            <div className={classes.text} key={index}>
              {item}
            </div>
          );
        })}
      </label>
    </div>
  );
};
export default ToggleText;
