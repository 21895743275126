import { createSlice } from "@reduxjs/toolkit";

const teamManageSlice = createSlice({
  name: "teamManage",
  initialState: {
    selectedSubscription: null,
    subscriptionUsers: [],
  },
  reducers: {
    setSelectedSubscription(state, actions) {
      state.selectedSubscription = actions.payload;
    },
    setSubscriptionUsers(state, actions) {
      state.subscriptionUsers = actions.payload;
    },
    updateSubscriptionUsers(state, actions) {
      state.subscriptionUsers.push(actions.payload);
    },
  },
});
export const teamManageActions = teamManageSlice.actions;
export default teamManageSlice.reducer;
