import SettingPageLayout from "../components/SettingPageLayout";
import RemoveAccount from "../components/RemoveAccount";
const AccountSetting = () => {
  return (
    <SettingPageLayout>
      <RemoveAccount />
    </SettingPageLayout>
  );
};
export default AccountSetting;
