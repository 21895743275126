// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YourLicense_licenseBox__4MQdh {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.YourLicense_cardsBox__Uvi02 {
  display: flex;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/modules/yourLicense/pages/style/YourLicense.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,uBAAA;EACA,uBAAA;AACF;;AACA;EACE,aAAA;EACA,WAAA;AAEF","sourcesContent":[".licenseBox {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: flex-start;\n}\n.cardsBox {\n  display: flex;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseBox": `YourLicense_licenseBox__4MQdh`,
	"cardsBox": `YourLicense_cardsBox__Uvi02`
};
export default ___CSS_LOADER_EXPORT___;
