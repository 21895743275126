import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/wTAdmin/WorkSpaces";
const WorkspaceRepository = {
  getAllWorkspaceList() {
    return BaseRepository.get(`${resource}`);
  },
  createWorkspace(body) {
    return BaseRepository.post(`${resource}`, body);
  },
  GetOwnerWorkspaces() {
    return BaseRepository.get(`${resource}/GetOwnerWorkspaces`);
  },
  GetWorkspacesDetail() {
    return BaseRepository.get(`${resource}/GetWorkspacesDetail`);
  },
  UpdateWorkspace(body) {
    return BaseRepository.put(`${resource}`, body);
  },
  deleteWorkspace(id) {
    return BaseRepository.delete(`${resource}?id=${id}`);
  },
  GetCountryList() {
    return BaseRepository.get(`${resource}/GetCountryList`);
  },
};
export default WorkspaceRepository;
