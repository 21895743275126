import classes from "./style/EditWorkspace.module.scss";
import BaseTable from "../../../components/base/BaseTable";
import WorkspaceForm from "../../workspace/components/WorkspaceForm";
import { User, Calendar, Trash, Edit, UserAdd } from "iconsax-react";
import Modal from "../../../components/base/Modal";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { RepoFactory } from "../../../baseRepository/Factory";
const WorkspaceRepository = () => RepoFactory.get("workspace");
const EditWorkspace = () => {
  const dispatch = useDispatch();
  const workspaceListHaveLicenseAndShowInHeader = useSelector(
    (state) => state.workspace.workspaceList
  );
  const [workspaceList, setWorkspaceList] = useState();
  const [modalFlag, setModalFlag] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState();

  const exitModal = (status) => {
    setModalFlag(status);
  };
  const editButtonHandler = (workspace) => {
    setSelectedWorkspace(workspace);
    setModalFlag(true);
  };

  const GetOwnerWorkspaces = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    WorkspaceRepository()
      .GetWorkspacesDetail()
      .then((res) => {
        let array = [];
        res.data.value.workSpaces.forEach((element) => {
          array.push({ ...element, title: element.name });
        });
        setWorkspaceList(array);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  useEffect(() => {
    GetOwnerWorkspaces();
  }, []);
  useEffect(() => {}, [workspaceList]);

  const deleteWorkspace = (id) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    WorkspaceRepository()
      .deleteWorkspace(id)
      .then((res) => {
        GetOwnerWorkspaces();
        dispatch(errorActions.setShowToast(true));
        dispatch(
          errorActions.setError({
            message: [{ Message: "Delete succesfully" }],
            statusCode: 200,
          })
        );
      })
      .catch((error) => {
        dispatch(errorActions.setShowToast(true));
        dispatch(
          errorActions.setError({
            message: error.response?.data.Reasons,
            statusCode: error.response.status,
          })
        );
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const addUser = () => {};
  return (
    <div className={classes.editBox}>
      <div className={classes.workspaceHeader}>
        <h1 className={classes.title}>
          Edit <span className={classes.colorTitle}>Workspace</span>
        </h1>
        <span className={classes.titleCaption}>
          You can invite up to 4 users in the form. so please input users email
          address.
        </span>
      </div>
      <div className={classes.table}>
        {workspaceList && workspaceList.length > 0 && (
          <BaseTable
            data={workspaceList.map((row) => {
              return [
                {
                  width: "20%",
                  row: <div className={classes.tableRow}>{row.name}</div>,
                },
                {
                  width: "30%",
                  row: (
                    <div className={classes.tableRow}>
                      <Calendar />
                      <span className={classes.text}>
                        {new Date(row.created).toLocaleDateString()}
                      </span>
                    </div>
                  ),
                },
                {
                  width: "10%",
                  row: (
                    <div className={classes.tableRow}>
                      <User />
                      <span className={classes.text}>{row.userCount}</span>
                    </div>
                  ),
                },
                {
                  width: "30%",
                  row: (
                    <div className={classes.tableRow}>
                      <span className={classes.text}>{row.typeName}</span>
                    </div>
                  ),
                },
                {
                  width: "10%",
                  row: (
                    <div className={classes.actionBar}>
                      <div>
                        <Trash onClick={(e) => deleteWorkspace(row.id)} />
                      </div>
                      <div onClick={(e) => editButtonHandler(row)}>
                        <Edit />
                      </div>
                    </div>
                  ),
                },

                ,
                ,
                ,
              ];
            })}
          />
        )}
      </div>
      {modalFlag && (
        <Modal
          header="Edit Workspace"
          exitAction={exitModal}
          body={
            <WorkspaceForm
              hasEdit={true}
              seletedWorkspaceDateForEdit={selectedWorkspace}
              exitAction={exitModal}
            />
          }
        />
      )}
    </div>
  );
};
export default EditWorkspace;
