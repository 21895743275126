import classes from "./style/LicenseManagment.module.scss";
import ProLicenseCard from "../../../components/base/ProLicenseCard";
import FreeLisenceCard from "../../../components/base/FreeLicenseCard";
import { errorActions } from "../../../store/error-slice";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { loadingActions } from "../../../store/loading-slice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useParams } from "react-router-dom";
import { workspaceActions } from "../../../modules/workspace/store/workspace-slice";
const ProductRepository = () => RepoFactory.get("product");
const SubscriptionRepository = () => RepoFactory.get("subscription");
const LicenseManagment = () => {
  const params = useParams();
  const workspaceId = params.workspaceId;
  const workspaceName = params.workspaceName;
  const hasError = useSelector((state) => state.error.hasError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [unRegisterSubscription, setUnRegisterSubscription] = useState([]);
  const [WorkspaceSubscription, setWorkspaceSubscription] = useState();
  const getProductList = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let productList = [];
    await ProductRepository()
      .getProductList()
      .then((res) => {
        productList = res.data.value.products;
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        getWorkspaceSubscription(productList);
      });
  };
  const getWorkspaceSubscription = async (subscriptionList) => {
    let array = [];
    if (workspaceId) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(""));
      await SubscriptionRepository()
        .getWorkspaceSubscription(workspaceId)
        .then((res) => {
          array = res.data.value.subscription;
          setWorkspaceSubscription(array);
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
          setUnRegisterList(array, subscriptionList);
        });
    } else {
      dispatch(loadingActions.setHasLoading(false));
      setUnRegisterSubscription(subscriptionList);
    }
  };
  const setUnRegisterList = (array, subscriptionList) => {
    if (array) {
      let unRegisterList = subscriptionList;
      array.forEach((element) => {
        unRegisterList = unRegisterList.filter(
          (item) => item.description !== element.productName
        );
      });
      setUnRegisterSubscription(unRegisterList);
    }
  };
  const openWorkSpaceListAndBuyPlan = (item) => {
    navigate(`/workspace/productId/${item.id}`);
    dispatch(
      workspaceActions.setSelectedWorkspace({
        id: workspaceId,
        name: workspaceName,
        title: workspaceName,
      })
    );
  };

  useEffect(() => {
    setUnRegisterSubscription([]);
    setWorkspaceSubscription([]);
    getProductList();
  }, [workspaceId]);
  return (
    <div className={classes.cardLayer}>
      {WorkspaceSubscription &&
        WorkspaceSubscription.map((item, index) => {
          return (
            <div key={index} className={classes.cardItem}>
              <ProLicenseCard
                productName={item.productName}
                planType={item.subscriptionPlanName}
                creationDate={item.startDate}
                expireDate={item.endDate}
                workspaceName={workspaceName}
                clickHandler={(e) => {
                  navigate(
                    `/workspace/productId/${item.productId}?status=${"update"}`
                  );
                }}
              />
            </div>
          );
        })}
      {unRegisterSubscription &&
        unRegisterSubscription.map((item, index) => {
          return (
            <div key={index} className={classes.cardItem}>
              <FreeLisenceCard
                productName={item.description}
                planType={"Free Plan"}
                workspaceName={workspaceName}
                clickHandler={(e) => {
                  openWorkSpaceListAndBuyPlan(item);
                }}
              />
            </div>
          );
        })}
      {hasError && <ErrorHandling />}
    </div>
  );
};
export default LicenseManagment;
