import { Route, Routes } from "react-router-dom";
import Services from "../pages/Services";
const ServicesRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Services />} />
    </Routes>
  );
};

export default ServicesRouter;
