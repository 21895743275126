/**
 * <SettingCard
          size="lg"
          title="Wingstech News"
          description="You can invite up to 4 users in the form. so please input users email address."
          cardAction={(e) => cardAction()}
        />
 */
import classes from "./style/SettingCard.module.scss";
import ToggleSwitch from "../../../components/base/ToggleSwitch";
const SettingCard = (props) => {
  return (
    <div
      className={
        props.size === "lg" ? classes.largCardBox : classes.smallCardBox
      }
    >
      <div className={classes.cardHeader}>
        <h1 className={classes.cardTitle}>{props.title}</h1>
        <span className={classes.cardDescription}>{props.description}</span>
      </div>
      <ToggleSwitch
        checked={props.checked}
        disabled={props.disabled}
        checkAction={props.cardAction}
      />
    </div>
  );
};
export default SettingCard;
