/**
 * <Modal
          size="sm"
          header="test"
          exitAction={exitModal}
          body={
            <div className={classes.modalBody}>
              <span>Are you sure you want to delete this Punch time? This action cannot be undone.</span>
            </div>
          }
          action={
            <div className={classes.buttonBox}>
              <BaseButton title="Delete" color="secondary" hasRightIcon={false} hasLeftIcon={false}></BaseButton>
              <BaseButton title="Cancel" color="primary" hasRightIcon={false} hasLeftIcon={false}></BaseButton>
            </div>
          }></Modal>
 */
import { useEffect } from "react";
import classes from "./style/Modal.module.scss";
import { CloseCircle } from "iconsax-react";

const Modal = (props) => {
  const exitHandler = () => {
    return props.exitAction(false);
  };
  useEffect(() => {
    props.exitAction(true);
  }, [props]);
  return (
    <div className={classes.backgroundModal}>
      <div
        className={
          props.size === "sm"
            ? classes.smallModal
            : props.size === "lg"
            ? classes.largModal
            : classes.medModal
        }
      >
        <div className={classes.modalHeader}>
          <h1 className={classes.headerText}>{props.header}</h1>
          <div className={classes.exitButton} onClick={(e) => exitHandler()}>
            <CloseCircle />
          </div>
        </div>
        <div className={classes.body}>{props.body}</div>
        <div className={classes.action}>{props.action}</div>
      </div>
    </div>
  );
};
export default Modal;
