import DashboardLayout from "../../../view/layouts/DashboardLayout";
import ArrowIcon from "../../../assets/image/settingArrow.svg";
import classes from "./style/SettingPageLayout.module.scss";
const SettingPageLayout = (props) => {
  return (
    <DashboardLayout>
      <div className={classes.settingContainer}>
        <div className={classes.settingTitle}>
          <div className={classes.titleHeader}>
            <h1 className={classes.headerText}>
              Setting
              <div className={classes.titleIcon}>
                <img src={ArrowIcon} alt="Arrow"></img>
              </div>
            </h1>
          </div>
          <span className={classes.titleCaption}>
            You can invite up to 4 users in the form. so please input users
            email address.
          </span>
        </div>
        {props.children}
      </div>
    </DashboardLayout>
  );
};
export default SettingPageLayout;
