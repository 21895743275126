import classes from "./style/ChangePassword.module.scss";
import Input from "../../../components/base/Input";
import { Key } from "iconsax-react";
import BaseButton from "../../../components/base/BaseButton";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { RepoFactory } from "../../../baseRepository/Factory";
import { loadingActions } from "../../../store/loading-slice";
import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "../../../store/error-slice";
import { useState } from "react";
const authRepository = () => RepoFactory.get("auth");

const ChangePassword = () => {
  const dispatch = useDispatch();
  const hasError = useSelector((state) => state.error.hasError);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const KeyIcon = () => {
    return <Key />;
  };
  const oldPasswordHandler = (e) => {
    setOldPassword(e.target.value);
  };
  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };
  const changePassword = () => {
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    dispatch(loadingActions.setHasLoading(true));
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    authRepository()
      .changePassword(body)
      .then((res) => {})
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  return (
    <div className={classes.changePasswordContainer}>
      <div className={classes.passwordHeader}>
        <h1 className={classes.title}>
          Change <span className={classes.colorTitle}>Password</span>
        </h1>
      </div>
      <div className={classes.passwordInput}>
        <Input
          placeholder="Enter Your Old Password"
          type="password"
          name="oldPassword"
          icon={KeyIcon()}
          value={oldPassword}
          handleChange={(e) => oldPasswordHandler(e)}
        />
        <Input
          placeholder="Enter Your New Password"
          type="password"
          name="newPassword"
          icon={KeyIcon()}
          value={newPassword}
          handleChange={(e) => newPasswordHandler(e)}
        />
      </div>
      <BaseButton
        size="lg"
        title="Submit"
        color="secondary"
        onClickHandler={(e) => changePassword()}
      />
      {hasError && <ErrorHandling />}
    </div>
  );
};
export default ChangePassword;
