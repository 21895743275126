import classes from "./style/ProductCard.module.scss";
import check from "../../assets/image/icon/check.svg";
import BaseButton from "./BaseButton";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
const ProductCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.productCard}>
      <div className={classes.body}>
        <div className={classes.signup}>{props.icon}</div>
        <div className={classes.title}>
          <h1>{props.title}</h1>
        </div>
        <div className={classes.items}>
          {props.items.map((item, index) => {
            return (
              <div key={index} className={classes.item}>
                <div className={classes.checkbox}>
                  <img src={check} alt="check" />
                </div>
                <div className={classes.text}>
                  <span>{item}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.btn}>
        {props.hasLisence ? (
          <a href={props.link} target="_blank" className={classes.linkBtn}>
            {props.btnText} <ArrowRight></ArrowRight>
          </a>
        ) : (
          <BaseButton
            size="lg"
            title={props.btnText}
            hasRightIcon={true}
            hasBlock={true}
            onClickHandler={(e) => navigate(props.link)}
          />
        )}
      </div>
    </div>
  );
};
export default ProductCard;
