/**
 *  <PriceCard
        type:"recommended"
        description="Lorem ipsum dolor sit amet. Eum eveniet earum id debitis consequuntur nam"
        price="$49" 
        items={[
          "Customized drug treatments",
          "Comprehensive diagnosis of ADHD",
          "Comprehensive diagnosis of ADHD",
        ]}
        btnText="Read More"

      ></PriceCard>
 */
import classes from "./style/PriceCard.module.scss";
import BaseButton from "./BaseButton";
import Badge from "./Badge";
import check from "../../assets/image/icon/primaryCheck.svg";
import secondaryCrown from "../../assets/image/icon/secondaryCrown.svg";
import primaryCrown from "../../assets/image/icon/primaryCrown.svg";

const PriceCard = (props) => {
  const buttonColor = () => {
    if (props.type === "recommended") {
      return "secondary";
    } else {
      return "outlineSecondary";
    }
  };
  const iconColor = () => {
    if (props.type === "recommended") {
      return primaryCrown;
    } else {
      return secondaryCrown;
    }
  };
  const selectedPriceCardHandler = () => {
    props.selectedPriceCardHandler(props.id);
  };
  return (
    <div
      className={
        props.type === "recommended"
          ? classes.recommendedPriceCard
          : classes.priceCard
      }
    >
      {props.type === "recommended" && (
        <div className={classes.badgeContainer}>
          <Badge title="Popular Choice" color="primary" />
        </div>
      )}
      <div className={classes.details}>
        <div className={classes.titleBox}>
          <div
            className={
              props.type === "recommended"
                ? classes.primaryIconBox
                : classes.iconBox
            }
          >
            <img src={iconColor()} alt="crown" />
          </div>
          <h1 className={classes.titleText}>{props.name}</h1>
          <div className={classes.titleDescription}>
            <h5 className={classes.descriptionText}>{props.description}</h5>
            <h1 className={classes.titlePrice}>
              {props.price}
              <span className={classes.captionPriceSpan}>
                {props.billingPeriod === "Monthly" ? "/mth" : "/year"}
              </span>
            </h1>
          </div>
        </div>
        <div className={classes.itemLayer}>
          <div className={classes.itemBox}>
            {props.items.map((item, index) => {
              return (
                <div key={index} className={classes.item}>
                  <div className={classes.itemCheck}>
                    <img src={check} alt="check" />
                  </div>
                  <div className={classes.itemText}>
                    <span>{item}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={classes.buttonBox}>
        <BaseButton
          size="lg"
          title="Start Free Trial"
          color={buttonColor()}
          hasBlock={true}
          onClickHandler={(e) => selectedPriceCardHandler()}
        />
      </div>
    </div>
  );
};
export default PriceCard;
