/*
 <Badge
    title="Categories"
  ></Badge>
  
*/
const Badge = (props) => {
  return (
    <div className={props.color === "primary" ? "primary-badge" : "badge"}>
      <span>{props.title}</span>
    </div>
  );
};
export default Badge;
