import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgetPassword from "../pages/ForgetPassword";

const AuthRouter = () => {
  return (
    <Routes>
      <Route path="/login" exact element={<Login />} />
      <Route path="*" element={<Login />} />
      <Route path="/signup" exact element={<SignUp />} />
      <Route path="/forget-password" exact element={<ForgetPassword />} />
    </Routes>
  );
};

export default AuthRouter;
