import classes from "./style/UserTable.module.scss";
import ArrowIcon from "../../../assets/image/icon/twistedArrow.svg";
import BaseTable from "../../../components/base/BaseTable";
import { Message } from "iconsax-react";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { teamManageActions } from "../store/teamManage-slice";
import { useDispatch, useSelector } from "react-redux";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useEffect } from "react";
import { Trash } from "iconsax-react";
const teamManagementRepository = () => RepoFactory.get("teamManagement");
const UserTable = () => {
  const dispatch = useDispatch();
  const selectedSubscription = useSelector(
    (state) => state.teamManage.selectedSubscription
  );
  const subscriptionUsers = useSelector(
    (state) => state.teamManage.subscriptionUsers
  );

  const getUserList = async () => {
    if (selectedSubscription) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      await teamManagementRepository()
        .getTeamMembers(selectedSubscription.id)
        .then((res) => {
          dispatch(
            teamManageActions.setSubscriptionUsers(res.data.value.members)
          );
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(teamManageActions.setSubscriptionUsers([]));
    }
  };
  const deleteUserPermission = async (id) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    await teamManagementRepository()
      .deleteUserPermission(id)
      .then((res) => {
        getUserList();
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getUserList();
  }, [selectedSubscription]);
  return (
    <div className={classes.userBox}>
      {subscriptionUsers && subscriptionUsers.length > 0 && (
        <>
          <div className={classes.userBoxTitle}>
            <h1 className={classes.titleText}>
              Avalible <span className={classes.colorText}>Users</span>
            </h1>
            <div className={classes.titleIcon}>
              <img src={ArrowIcon} alt="Arrow"></img>
            </div>
          </div>
          <div className={classes.table}>
            <BaseTable
              color="primary"
              data={subscriptionUsers.map((user) => {
                return [
                  {
                    title: "Name",
                    row: (
                      <div className={classes.tableRow}>{user.nickName}</div>
                    ),
                    width: "20%",
                  },
                  {
                    title: "ProductName",
                    row: (
                      <div className={classes.tableRow}>
                        {selectedSubscription?.productName}
                      </div>
                    ),
                    width: "20%",
                  },
                  {
                    title: "Email",
                    hideTitleInMobile: true,
                    row: (
                      <div className={classes.tableRow}>
                        <Message />
                        {user.userName}
                      </div>
                    ),
                    width: "35%",
                  },
                  {
                    title: "Role",
                    row: (
                      <div className={classes.tableRow}>{user.roleName}</div>
                    ),
                    width: "15%",
                  },
                  {
                    title: "Action",
                    hideTitleInMobile: true,
                    row: (
                      <div className={classes.actions}>
                        <Trash
                          onClick={(e) =>
                            deleteUserPermission(
                              user.permissionId
                                ? user.permissionId
                                : user.invitiationId
                            )
                          }
                        />
                      </div>
                    ),
                    width: "10%",
                  },
                ];
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default UserTable;
