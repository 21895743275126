import classes from "./style/WorkspaceList.module.scss";
import SelectInput from "../../../components/base/SelectInput";
import TextButton from "../../../components/base/TextButton";
import BaseButton from "../../../components/base/BaseButton";
import { User } from "iconsax-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workspaceActions } from "../store/workspace-slice";
import { useNavigate } from "react-router-dom";
const WorkspaceList = (props) => {
  const navigate = useNavigate();
  const [showPricingCard, setShowPricingCard] = useState(false);
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector(
    (state) => state.workspace.selectedWorkspace
  );
  const openNewWorkSpace = () => {
    props.handleChange(true);
  };

  const UserIcon = () => {
    return <User />;
  };
  const changeSelectedWorkspace = (selected) => {
    dispatch(workspaceActions.setSelectedWorkspace(selected));
  };
  const openYourLicensePage = () => {
    navigate(`/your-license/${selectedWorkspace.name}/${selectedWorkspace.id}`);
  };
  useEffect(() => {
    if (selectedWorkspace) {
      changeSelectedWorkspace(selectedWorkspace);
    } else if (selectedLicensedWorkspace) {
      changeSelectedWorkspace({
        ...selectedLicensedWorkspace,
        title: selectedLicensedWorkspace.name,
      });
    } else if (props.workspacesList.length) {
      changeSelectedWorkspace(props.workspacesList[0]);
    }
  }, [selectedLicensedWorkspace]);
  useEffect(() => {
    if (selectedWorkspace?.hasCodeInOne && props.productId === "1") {
      setShowPricingCard(false);
    } else if (selectedWorkspace?.hasCharmDb && props.productId === "2") {
      setShowPricingCard(false);
    } else {
      setShowPricingCard(true);
    }
  }, [selectedWorkspace]);
  return (
    <div className={classes.workspaceListLayer}>
      <div className={classes.workspaceBox}>
        <div className={classes.workspaceSelectBox}>
          <h1 className={classes.selectTitle}>Select Your workspace</h1>
          <SelectInput
            selectedItem={selectedWorkspace}
            icon={UserIcon()}
            placeHolder="Select an item ..."
            options={props.workspacesList}
            handleChange={(e) => changeSelectedWorkspace(e)}
          />
        </div>
        <div className={classes.workspaceCaptionBox}>
          <h5 className={classes.captionTitle}>Do you Want New Workspace?</h5>
          <TextButton
            size="lg"
            title="Create New Workspace"
            color="primary"
            onClickHandler={(e) => openNewWorkSpace()}
          />
        </div>
        {showPricingCard ? (
          <BaseButton
            size="sm"
            title="Price model selection"
            color="secondary"
            hasRightIcon={true}
            onClickHandler={(e) => props.openPlanListHandler()}
          ></BaseButton>
        ) : (
          <BaseButton
            size="sm"
            title="You have plane . You can see it "
            color="secondary"
            hasRightIcon={true}
            onClickHandler={(e) => openYourLicensePage()}
          ></BaseButton>
        )}
      </div>
    </div>
  );
};
export default WorkspaceList;
