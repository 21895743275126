import classes from "./style/StepTwoForgetPasswordForm.module.scss";
import BaseAuthForm from "./BaseAuthForm";
import BaseButton from "../../../components/base/BaseButton";
import Input from "../../../components/base/Input";
import { Key } from "iconsax-react";
import { useEffect, useState } from "react";
import { RepoFactory } from "../../../baseRepository/Factory";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
const authRepository = () => RepoFactory.get("auth");
const StepTwoForgetPasswordForm = (props) => {
  const dispatch = useDispatch();
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [digitIndex, setDigitIndex] = useState(0);
  const hasError = useSelector((state) => state.error.hasError);
  const [passwordInput, setPasswordInput] = useState("");
  const [repeatPasswordInput, setRepeatPasswordInput] = useState("");
  const KeyIcon = () => {
    return <Key />;
  };
  const digitOnchangeHandler = (e, index) => {
    const re = /^[0-9\b]+$/;
    console.log(e.target.value.length);

    let digitsTemp = [...digits];
    Array.from(e.target.value).map((digit, counter) => {
      if (digit !== "" && re.test(digit)) {
        if (digitsTemp[index] === "") {
          digitsTemp[index] = digit[0];
        }
        setDigits(digitsTemp);
        index += 1;
      }
      return setDigitIndex(index);
    });
  };
  const deleteDigitHandler = (e, index) => {
    let digitsTemp = [...digits];
    if (e.key === "Backspace") {
      digitsTemp[index] = "";
      setDigits(digitsTemp);
      setDigitIndex(index - 1);
    }
  };
  const passwordHandler = (e) => {
    setPasswordInput(e.target.value);
  };
  const repeatPasswordHandler = (e) => {
    setRepeatPasswordInput(e.target.value);
  };

  const openStepThreeForm = () => {
    if (passwordInput === repeatPasswordInput) {
      let code = digits.join("");
      dispatch(errorActions.setHasError(false));
      dispatch(errorActions.setError(null));
      dispatch(loadingActions.setHasLoading(true));
      if (code.length === 6) {
        let body = {
          confirmationCode: code,
          username: props.userName,
          newPassword: passwordInput,
        };
        authRepository()
          .ResetPassword(body)
          .then((res) => {
            dispatch(errorActions.setHasError(true));
            props.handleChange(3);
          })
          .catch((error) => {
            dispatch(errorActions.setHasError(true));
            dispatch(errorActions.setError(error.response?.data.Reasons));
          })
          .finally(() => {
            dispatch(loadingActions.setHasLoading(false));
          });
      } else {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError([
            {
              Message:
                "Something went wrong while attempting to sign up. Please try again.",
            },
          ])
        );
      }
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError([
          {
            Message:
              "Password and Repeat Password Are not at the same ! please try again.",
          },
        ])
      );
    }
  };
  useEffect(() => {
    let nextSibling = document.getElementById("digit" + digitIndex);
    if (digitIndex > 5) nextSibling = document.getElementById("digit5");
    if (digitIndex < 0) nextSibling = document.getElementById("digit0");

    // If found, focus the next field
    if (nextSibling !== null) {
      nextSibling.focus();
    }
  }, [digitIndex]);
  const ResendVerificationCode = () => {
    let body = {
      username: props.userName,
    };
    authRepository()
      .ResendVerificationCode(body)
      .then((res) => {})
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      });
  };
  return (
    <BaseAuthForm pageType="signUp">
      <div className={classes.registerBox}>
        <div className={classes.inputBox}>
          <h1 className={classes.title}>Enter 6-digits code</h1>
          <div className={classes.codeBox}>
            {digits.map((digit, index) => {
              return (
                <Input
                  type="number"
                  maxLength={1}
                  id={"digit" + index}
                  name={"digit" + index}
                  circleShape={true}
                  handleChange={(e) => digitOnchangeHandler(e, index)}
                  keyHandler={(e) => deleteDigitHandler(e, index)}
                  value={digit}
                />
              );
            })}
          </div>
          <div className={classes.passwordBox}>
            <Input
              icon={KeyIcon()}
              placeholder="Enter Your Password"
              type="password"
              name="password"
              handleChange={passwordHandler}
            />
            <Input
              icon={KeyIcon()}
              placeholder="Repeat Your Password"
              type="password"
              name="repeatPassword"
              handleChange={repeatPasswordHandler}
            />
          </div>
          {!hasError && (
            <div className={classes.sentEmailNotification}>
              <h5 className={classes.sent}>
                We sent a mail to your Email address
              </h5>
              <h5 className={classes.email}>{props.userName}</h5>
            </div>
          )}
          {hasError && <ErrorHandling />}
        </div>
        <BaseButton
          size="lg"
          hasBlock={true}
          title="Continue"
          color="secondary"
          hasRightIcon={true}
          onClickHandler={openStepThreeForm}
        />
        <span className={classes.resendCode}>
          You didnt recieve code?
          <div
            className={classes.link}
            onClick={(e) => ResendVerificationCode()}
          >
            Resend it
          </div>
        </span>
      </div>
    </BaseAuthForm>
  );
};
export default StepTwoForgetPasswordForm;
