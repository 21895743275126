import { createSlice } from "@reduxjs/toolkit";
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    userInfo: null,
  },
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setUserInfo(state, action) {
      var JWTtoken = action.payload.split(".")[1];
      var base64 = JWTtoken.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      state.userInfo = JSON.parse(jsonPayload);
    },
    logOut(state) {
      var domain = process.env.REACT_APP_DOMAIN;
      console.log(process.env.REACT_APP_DOMAIN);
      state.userInfo = null;
      localStorage.clear();
      function delete_cookie() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie =
            name +
            "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=" +
            domain;
        }
      }
      delete_cookie();
    },
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
