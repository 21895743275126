import { Route, Routes } from "react-router-dom";
import Support from "../pages/Support";

const SupportRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Support />} />
    </Routes>
  );
};

export default SupportRouter;
