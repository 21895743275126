import { useState } from "react";
import AuthLayout from "../../../view/layouts/AuthLayout";
import SignUpForm from "../components/SignUpForm";
import StepTwoSignUpForm from "../components/StepTwoSignUpForm";
import SuccessSignupForm from "../components/SuccessSignupForm";
const SignUp = () => {
  const [stepNumber, setStepNumber] = useState(1);
  const [userName, setUserName] = useState("");
  const changeStep = (value) => {
    setStepNumber(value);
  };
  const changeUserName = (value) => {
    setUserName(value);
  };
  return (
    <div>
      <AuthLayout>
        {stepNumber === 1 && (
          <SignUpForm
            handleChange={changeStep}
            changeUserName={changeUserName}
          />
        )}
        {stepNumber === 2 && (
          <StepTwoSignUpForm handleChange={changeStep} userName={userName} />
        )}
        {stepNumber === 3 && <SuccessSignupForm handleChange={changeStep} />}
      </AuthLayout>
    </div>
  );
};
export default SignUp;
