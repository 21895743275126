import { useState, useEffect } from "react";
import classes from "./style/Menu.module.scss";
import {
  KeyboardOpen,
  Profile2User,
  Edit,
  Setting2,
  I24Support,
  NotificationBing,
  Key,
  Trash,
  Home,
  Logout,
  ArrowDown2,
} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../modules/auth/store/auth-slice";
import { dashboardActions } from "../../modules/dashboard/store/dashboard-slice";
import HeaderDropDown from "./HeaderDropDown";
const Menu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workspaceList = useSelector((state) => state.workspace.workspaceList);
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const [menu, setMenu] = useState([
    {
      title: "Your license",
      icon: <KeyboardOpen />,
      link:
        workspaceList && workspaceList.length
          ? `/your-license/${selectedLicensedWorkspace?.name}/${selectedLicensedWorkspace?.id}`
          : "/your-license",
      selected: false,
    },
    {
      title: "Team management",
      icon: <Profile2User />,
      link: "/team-management",
      selected: false,
    },

    {
      title: "Setting",
      icon: <Setting2 />,
      selected: false,
      expandIcon: <ArrowDown2 />,
      showChildren: false,
      children: [
        {
          title: "Email Notification",
          icon: <NotificationBing />,
          link: "/setting/email-notification",
          selected: false,
        },
        {
          title: "Change Password",
          icon: <Key />,
          link: "/setting/change-password",
          selected: false,
        },
        {
          title: "Edit Workspace",
          icon: <Edit />,
          link: "/setting/edit-workspace",
          selected: false,
        },
        {
          title: "Remove Account",
          icon: <Trash />,
          link: "/setting/remove-account",
          selected: false,
        },
      ],
    },
    {
      title: "Support",
      icon: <I24Support />,
      link: "/support",
      selected: false,
    },
  ]);
  const goToNextPage = async (selectedMenu) => {
    if (selectedMenu.link) {
      navigate(selectedMenu.link);
      hideMenuHandler();
    } else if (selectedMenu.children) {
      if (menu) {
        let tempMenu = menu.map((item) => {
          if (
            item.title === selectedMenu.title &&
            item.showChildren === false
          ) {
            item.showChildren = true;
            item.selected = true;
          } else if (
            item.title === selectedMenu.title &&
            item.showChildren === true
          ) {
            item.showChildren = false;
            item.selected = false;
          } else {
            item.showChildren = false;
            item.selected = false;
          }
          return item;
        });
        setMenu(tempMenu);
      }
    }
  };
  const goToNextChildPage = async (selectedMenu) => {
    if (selectedMenu.link) {
      navigate(selectedMenu.link);
      hideMenuHandler();
    }
  };
  const hideMenuHandler = () => {
    dispatch(dashboardActions.setShowSideBar(false));
  };
  const logout = () => {
    localStorage.removeItem("selectedLicensedWorkspace");
    dispatch(authActions.logOut());
    hideMenuHandler();
  };
  useEffect(() => {
    let path = window.location.pathname;
    if (menu) {
      let tempMenu = menu.map((item) => {
        if (item.link === path) {
          item.selected = true;
        } else if (path.includes(item.link)) {
          item.selected = true;
          item.showChildren = true;
        }
        return item;
      });
      setMenu(tempMenu);
    }
  }, []);
  return (
    <div className={classes.menu}>
      {window.innerWidth < 834 && (
        <>
          <HeaderDropDown exitAction={hideMenuHandler} />
          <div className={classes.menusItem}>
            <div className={classes.menuItemSection}>
              <div
                onClick={(e) =>
                  goToNextPage({
                    link: "/",
                  })
                }
                className={classes.menuItem}
              >
                <div className={classes.title}>
                  <Home />
                  <span>Home</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {menu &&
        menu.map((item, index) => {
          return (
            <div className={classes.menusItem} key={index}>
              <div className={classes.menuItemSection}>
                <div
                  onClick={(e) => goToNextPage(item)}
                  className={
                    item.selected ? classes.selectedMenuItem : classes.menuItem
                  }
                >
                  <div className={classes.title}>
                    {item.icon}
                    <span>{item.title}</span>
                  </div>
                  <div className={classes.expandIcon}>
                    {item.expandIcon && item.expandIcon}
                  </div>
                </div>

                {item.selected && (
                  <div className={classes.selectedMenuBorder}></div>
                )}
              </div>
              {item.showChildren &&
                item.children &&
                item.children.map((childManu, childIndex) => {
                  return (
                    <div
                      key={childIndex + Math.random()}
                      className={classes.childMenu}
                      onClick={(e) => {
                        goToNextChildPage(childManu);
                      }}
                    >
                      {childManu.icon}
                      {childManu.title}
                    </div>
                  );
                })}
            </div>
          );
        })}
      {window.innerWidth < 834 && (
        <div className={classes.menusItem}>
          <div className={classes.menuItemSection}>
            <div onClick={(e) => logout()} className={classes.menuItem}>
              <div className={classes.title}>
                <Logout />
                <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Menu;
