import classes from "./style/StepThreeForgetPasswordForm.module.scss";
import BaseAuthForm from "./BaseAuthForm";
import BaseButton from "../../../components/base/BaseButton";
import { useNavigate } from "react-router-dom";
import arrow from "../../../assets/image/Arrow_04.svg";
const StepThreeForgetPasswordForm = () => {
  const navigate = useNavigate();
  const goToLoginPage = async () => {
    navigate("/auth/login");
  };
  return (
    <BaseAuthForm>
      <div className={classes.baseForm}>
        <div className={classes.notificationBox}>
          <div className={classes.messageBox}>
            <h6 className={classes.title}>
              You Successfuly Changed Your Password
            </h6>
            <span className={classes.subtitle}>
              You Can Access Your Account By New Password.
            </span>
          </div>
          <BaseButton
            size="lg"
            hasBlock={true}
            title="Log In"
            hasRightIcon={true}
            color="secondary"
            onClickHandler={goToLoginPage}
          />
        </div>
        <div className={classes.arrow}>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
    </BaseAuthForm>
  );
};
export default StepThreeForgetPasswordForm;
