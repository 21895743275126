import SettingPageLayout from "../components/SettingPageLayout";
import EmailNotification from "../components/EmailNotification";
const EmailSetting = () => {
  return (
    <SettingPageLayout>
      <EmailNotification />
    </SettingPageLayout>
  );
};
export default EmailSetting;
