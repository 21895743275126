import classes from "./style/DeleteAccountForm.module.scss";
import { Key } from "iconsax-react";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
const DeleteAccountForm = () => {
  const KeyIcon = () => {
    return <Key />;
  };
  return (
    <div className={classes.deleteBox}>
      <div className={classes.deleteHeader}>
        <h1 className={classes.headerText}>Are you Sure to Delete Account?</h1>
        <span className={classes.headerCaption}>
          You can invite up to 4 users in the form. so please input users email
          address.
        </span>
      </div>
      <div className={classes.deleteInput}>
        <Input
          placeholder="Enter Your Password"
          type="password"
          name="password"
          icon={KeyIcon()}
        />
        <Input
          placeholder="Repeat Your Password"
          type="password"
          name="repeadPassword"
          icon={KeyIcon()}
        />
      </div>
      <BaseButton size="lg" title="Delete My Account" color="secondary" />
    </div>
  );
};
export default DeleteAccountForm;
