import { createSlice } from "@reduxjs/toolkit";
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    showSideBar: false,
  },
  reducers: {
    setShowSideBar(state, action) {
      state.showSideBar = action.payload;
    },
  },
});
export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
