import classes from "./style/Contact.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import { Call, Message } from "iconsax-react";
import arrow from "../../../assets/image/Arrow_05.svg";
const Contact = () => {
  const CallIcon = () => {
    return <Call />;
  };
  const MessageIcon = () => {
    return <Message />;
  };
  return (
    <div className={classes.contactBox}>
      <div className={classes.title}>
        <strong className={classes.ImpotantTitle}>Contact</strong>
        <span className={classes.contactTitle}>Our Team</span>
        <div className={classes.arrow}>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <span className={classes.contactDescription}>
        In person and on demand Meet us on our home turf in Sydney, Australia in
        November for our biggest service management event of the year.{" "}
      </span>
      <div className={classes.contactCall}>
        <BaseButton
          size="lg"
          title="+989027083838"
          color="secondary"
          hasBlock={true}
          hasLeftIcon={true}
          icon={CallIcon()}
        />
        <BaseButton
          size="lg"
          title="+98903456424"
          color="outlineSecondary"
          hasBlock={true}
          hasLeftIcon={true}
          icon={MessageIcon()}
        />
      </div>
    </div>
  );
};
export default Contact;
