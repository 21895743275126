import classes from "./style/ForgetPasswordForm.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { Sms } from "iconsax-react";
import BaseAuthForm from "./BaseAuthForm";
import { useState, useEffect } from "react";
import validator from "validator";
import { RepoFactory } from "../../../baseRepository/Factory";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
const authRepository = () => RepoFactory.get("auth");
const ForgetPasswordForm = (props) => {
  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState("");
  const [emailvalidat, setEmailValidate] = useState(false);
  const hasError = useSelector((state) => state.error.hasError);
  const emailOnChangeHandler = (e) => {
    setEmailInput(e.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (validator.isEmail(emailInput)) {
        setEmailValidate(true);
        dispatch(errorActions.setHasError(false));
        dispatch(errorActions.setError(null));
      } else if (emailInput.length) {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError([
            {
              Message:
                "Something went wrong while attempting to sign up. Please try again.",
            },
          ])
        );
      }
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [emailInput]);

  const SmsIcon = () => {
    return <Sms />;
  };

  const sendForgetPasswordEmail = () => {
    if (emailvalidat) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      let body = {
        username: emailInput,
      };
      authRepository()
        .ForgetPassword(body)
        .then((res) => {
          if (res.data.isSuccess) {
            props.changeUserName(emailInput);
            props.handleChange(2);
          }
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data.Reasons));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError([
          {
            Message:
              "Something went wrong while attempting to sign up. Please try again.",
          },
        ])
      );
    }
  };
  return (
    <BaseAuthForm pageType="forget-password">
      <div
        className={
          emailvalidat ? classes.inputBoxAfterValidate : classes.inputBox
        }
      >
        {hasError && <ErrorHandling />}
        <Input
          icon={SmsIcon()}
          placeholder="Enter Your Email Address"
          name="email"
          handleChange={emailOnChangeHandler}
        />
        <BaseButton
          size="lg"
          hasBlock={true}
          title="Continue"
          color="secondary"
          hasRightIcon={true}
          onClickHandler={sendForgetPasswordEmail}
        />
      </div>
    </BaseAuthForm>
  );
};
export default ForgetPasswordForm;
