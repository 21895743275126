import AuthRepository from "../modules/auth/repository/AuthRepository";
import WorkspaceRepository from "../modules/workspace/repository/WorkspaceRepository";
import ProductRepository from "../modules/product/repository/ProductRepository";
import SubscriptionPlansRepository from "../modules/subscriptionPlans/repository/SubscriptionPlansRepository";
import SubscriptionRepository from "../modules/subscription/repository/SubscriptionRepository";
import TeamManagementRepository from "../modules/TeamManagement/repository/TeamManagementRepository";
const repositories = {
  auth: AuthRepository,
  workspace: WorkspaceRepository,
  product: ProductRepository,
  subscriptionPlans: SubscriptionPlansRepository,
  subscription: SubscriptionRepository,
  teamManagement: TeamManagementRepository,
};

export const RepoFactory = {
  get: (name) => repositories[name],
};
