import classes from "./style/RemoveAccount.module.scss";
import BaseAlert from "../../../components/base/BaseAlert";
import DeleteAccountForm from "./DeleteAccountForm";
const RemoveAccount = () => {
  return (
    <div className={classes.removeBox}>
      <div className={classes.removeHeader}>
        <h1 className={classes.headerTitle}>Remove Account</h1>
        <span className={classes.headerCaption}>
          You can invite up to 4 users in the form. so please input users email
          address.
        </span>
      </div>
      <p className={classes.removeBoxDescription}>
        In person and on demand Meet us on our home turf in Sydney, Australia in
        November for our biggest service management event of the year. In person
        and on demand Meet us on our home turf in Sydney, Australia in November
        for our biggest service management event of the year.In person and on
        demand Meet us on our home turf in Sydney, Australia in November for our
        biggest service management event of the year.
      </p>
      <p className={classes.removeBoxDescription}>
        In person and on demand Meet us on our home turf in Sydney, Australia in
        November for our biggest service management event of the year. In person
        and on demand Meet us on our home turf in Sydney, Australia in November
        for our biggest service management event of the year.In person and on
        demand Meet us on our home turf in Sydney, Australia in November for our
        biggest service management event of the year.
      </p>
      <BaseAlert
        type="warning"
        description="In person and on demand Meet us on our home turf in Sydney, Australia in November for our biggest service management event of the year."
      />
      <div className={classes.removeTipLayer}>
        <div className={classes.tipBox}>
          <div className={classes.iconBox}>
            <input
              type="checkbox"
              id="offer"
              name="offer"
              value=""
              className="input-check-box"
              checked={true}
            />
          </div>
          <span className={classes.tipText}>
            In person and on demand Meet us on our home turf in Sydney,
            Australia in November for our biggest service management event of
            the year.
          </span>
        </div>
        <div className={classes.tipBox}>
          <div className={classes.iconBox}>
            <input
              type="checkbox"
              id="offer"
              name="offer"
              value=""
              className="input-check-box"
              checked={true}
            />
          </div>
          <span className={classes.tipText}>
            In person and on demand Meet us on our home turf in Sydney,
            Australia in November for our biggest service management event of
            the year.
          </span>
        </div>
      </div>
      <DeleteAccountForm />
    </div>
  );
};
export default RemoveAccount;
