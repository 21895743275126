import classes from "./style/TitleSection.module.scss";
import Highlight from "../../assets/image/icon/HighlightBlack.svg";
const TitleSection = (props) => {
  return (
    <div className={classes.titleSection}>
      <div className={classes.title}>
        {props.children}
        <div className={classes.HighlightImage}>
          <img src={Highlight} alt="Highlight"></img>
        </div>
      </div>
    </div>
  );
};
export default TitleSection;
