import { Route, Routes } from "react-router-dom";
import Profile from "../pages/Profile";

const ProfileRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Profile />} />
    </Routes>
  );
};

export default ProfileRouter;
