import { Route, Routes } from "react-router-dom";
import Notification from "../pages/Notification";
const NotificationRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Notification />} />
    </Routes>
  );
};

export default NotificationRouter;
