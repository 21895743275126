import classes from "./style/AuthLayout.module.scss";
import WLogo from "../../assets/image/logo/W.svg";
import Highlight from "../../assets/image/icon/HighlightWhite.svg";
const AuthLayout = (props) => {
  return (
    <>
      <div className={classes.authLayout}>
        <div className={classes.contentSection}>
          <div className={classes.startText}>
            <div className={classes.title}>
              <h3>
                Start Your <br></br> Jurney with Us.
              </h3>
            </div>
            <div className={classes.subtitle}>
              <h5>
                Discover the worlds best community of
                <br></br> traders and Bussiness owners.
              </h5>
            </div>
            <div className={classes.HighlightImage}>
              <img src={Highlight} alt="Highlight"></img>
            </div>
          </div>

          <div className={classes.logo}>
            <img src={WLogo} alt="W Logo" />
          </div>
        </div>
        <div className={classes.formSection}>{props.children}</div>
      </div>
      <div className={classes.responsiveLayout}>
        <div className={classes.responsiveContentSection}>
          <div className={classes.logo}>
            <img src={WLogo} alt="W Logo" />
          </div>
          <div className={classes.responsiveformSection}>{props.children}</div>
        </div>
      </div>
    </>
  );
};
export default AuthLayout;
