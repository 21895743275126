import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/wTAdmin/UserPermissions";
const TeamManagementRepository = {
  setUserPermission(body) {
    return BaseRepository.post(`${resource}`, body);
  },
  getTeamMembers(subscriptionId) {
    return BaseRepository.get(
      `${resource}/GetTeamMembers?subscriptionId=${subscriptionId}`
    );
  },
  deleteUserPermission(id) {
    return BaseRepository.delete(`${resource}?id=${id}`);
  },
};
export default TeamManagementRepository;
