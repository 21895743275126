/*
 <NotificationCard
          title="The main ivents in your life is robot fest"
          time="11.00-11.40 , 23 lesson"
 ></NotificationCard>
 */
import classes from "./style/NotificationCard.module.scss";
import { NotificationBing, Clock } from "iconsax-react";
const NotificationCard = (props) => {
  return (
    <div className={classes.notificationCard}>
      <div className={classes.iconSection}>
        <div className={classes.icon}>
          <NotificationBing />
        </div>
      </div>
      <div className={classes.text}>
        <h1>{props.title}</h1>
        {props.time && (
          <div className={classes.time}>
            <div className={classes.timeIcon}>
              <Clock></Clock>
            </div>
            <div className={classes.timeDetail}>
              <span>{props.time}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default NotificationCard;
