import classes from "./style/LoginForm.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { Sms, Key } from "iconsax-react";
import BaseAuthForm from "./BaseAuthForm";
import { useState } from "react";
import { RepoFactory } from "../../../baseRepository/Factory";
import { useNavigate } from "react-router-dom";
import { authActions } from "../store/auth-slice";
import { errorActions } from "../../../store/error-slice";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import validator from "validator";
import { useCookies } from "react-cookie";
const authRepository = () => RepoFactory.get("auth");
const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const hasError = useSelector((state) => state.error.hasError);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const isValidEmail = (email) => {
    if (email.length !== 0) {
      if (validator.isEmail(email)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const inputOnChangeHandler = (field, e) => {
    switch (field) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
  };

  const validateForm = () => {
    const newErrors = {
      email: !isValidEmail(email),
      password: password.length === 0,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };
  const resetInfo = () => {
    setEmail("");
    setPassword("");
    setErrors({
      email: false,
      password: false,
    });
  };
  const goToNextPage = async (link) => {
    navigate(link);
  };
  const Signin = () => {
    if (!validateForm()) {
      dispatch(loadingActions.setHasLoading(false));
      return;
    }
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    dispatch(loadingActions.setHasLoading(true));

    let body = {
      username: email,
      password: password,
    };
    authRepository()
      .Signin(body)
      .then((res) => {
        localStorage.setItem("idToken", res.data.value.idToken);
        setCookie("access_token", res.data.value.accessToken, {
          domain: process.env.REACT_APP_DOMAIN,
          path: "/",
        });
        dispatch(authActions.setUserInfo(res.data.value.idToken));
        dispatch(authActions.setIsAuthenticated(true));
        resetInfo();
        goToNextPage("/");
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const KeyIcon = () => {
    return <Key />;
  };
  const SmsIcon = () => {
    return <Sms />;
  };
  return (
    <BaseAuthForm pageType="login">
      <div className={classes.inputBox}>
        {hasError && <ErrorHandling />}
        <Input
          icon={SmsIcon()}
          placeholder="Enter Your Email Address"
          name="email"
          handleChange={(e) => inputOnChangeHandler("email", e)}
          invalid={errors.email}
          invalidMsg={
            email.length
              ? "Please insert Correct Email"
              : "Please Insert The Email"
          }
        />
        <Input
          icon={KeyIcon()}
          placeholder="Enter Your Password"
          name="password"
          type="password"
          handleChange={(e) => inputOnChangeHandler("password", e)}
          invalid={errors.password}
          invalidMsg="Please Insert The Password"
        />
        <BaseButton
          size="lg"
          hasBlock={true}
          title="Continue"
          color="secondary"
          onClickHandler={Signin}
        />
      </div>
    </BaseAuthForm>
  );
};
export default LoginForm;
