import classes from "./style/FreeLicenseCard.module.scss";
import { Messages1 } from "iconsax-react";
import BaseButton from "./BaseButton";
const FreeLisenceCard = (props) => {
  return (
    <div className={classes.cardLayer}>
      <div className={classes.cardHeader}>
        <div className={classes.chatBox}>
          <div className={classes.chatIconBox}>
            <Messages1 className={classes.chatIcon} />
          </div>
          <h1 className={classes.chatTitle}>{props.productName}</h1>
        </div>
        <div className={classes.planType}>
          <span className={classes.planTitle}>{props.planType}</span>
        </div>
      </div>
      <span className={classes.cardDescription}>
        Your journey begins with a thorough diagnosis and initial
      </span>
      <BaseButton
        size="lg"
        title="Try It"
        color="primary"
        hasBlock={true}
        onClickHandler={props.clickHandler}
      />
    </div>
  );
};
export default FreeLisenceCard;
