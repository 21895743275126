import classes from "./style/NewWorkspaceForm.module.scss";
import Input from "../../../components/base/Input";
import SelectInput from "../../../components/base/SelectInput";
import BaseButton from "../../../components/base/BaseButton";
import { Home, Mobile, House } from "iconsax-react";
import { useState, useEffect } from "react";
import { RepoFactory } from "../../../baseRepository/Factory";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import { useDispatch } from "react-redux";
import { workspaceActions } from "../store/workspace-slice";
const WorkspaceRepository = () => RepoFactory.get("workspace");
const WorkspaceForm = (props) => {
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);

  const [workspaceName, setWorkspaceName] = useState(
    props.hasEdit && props.seletedWorkspaceDateForEdit
      ? props.seletedWorkspaceDateForEdit?.name
      : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    props.hasEdit && props.seletedWorkspaceDateForEdit
      ? props.seletedWorkspaceDateForEdit?.phoneNumber
      : ""
  );
  const [address, setAddress] = useState(
    props.hasEdit && props.seletedWorkspaceDateForEdit
      ? props.seletedWorkspaceDateForEdit?.address
      : ""
  );
  const [countryCode, setCountryCode] = useState(null);
  const [type, setType] = useState(
    props.hasEdit && props.seletedWorkspaceDateForEdit
      ? {
          value: props.seletedWorkspaceDateForEdit?.type,
          title: props.seletedWorkspaceDateForEdit?.typeName,
        }
      : { value: "0", title: "Individual" }
  );
  const nameHandler = (e) => {
    setWorkspaceName(e.target.value);
  };
  const countryHandler = (country) => {
    setCountryCode(country);
  };
  const setTypeHandler = (selectedType) => {
    setType(selectedType);
  };
  const phoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value);
  };
  const addressHandler = (e) => {
    setAddress(e.target.value);
  };
  const openWorkspaceList = () => {
    props.handleChange(false);
  };
  const HomeIcon = () => {
    return <Home />;
  };
  const MobileIcon = () => {
    return <Mobile />;
  };
  const HouseIcon = () => {
    return <House />;
  };
  const createWorkspace = () => {
    if (workspaceName.length && countryCode?.value.length) {
      let body = {
        name: workspaceName,
        code: workspaceName,
        type: type?.value,
        countryCode: countryCode?.value,
        phoneNumber: phoneNumber,
        address: address,
      };
      WorkspaceRepository()
        .createWorkspace(body)
        .then((res) => {
          dispatch(
            workspaceActions.setSelectedWorkspace({
              ...res.data.value.workSpaces,
              title: res.data.value.workSpaces.name,
            })
          );
          setWorkspaceName("");
          setPhoneNumber("");
          setAddress("");
          setCountryCode(null);
          setType(null);
          openWorkspaceList();
        })
        .catch((error) => {
          dispatch(errorActions.setShowToast(true));
          dispatch(
            errorActions.setError({
              message: error.response?.data.Reasons,
              statusCode: error.response.status,
            })
          );
        });
    } else {
      dispatch(errorActions.setShowToast(true));
      dispatch(
        errorActions.setError({
          message: "Please, Fill input",
          statusCode: 400,
        })
      );
    }
  };
  const updateWorkspace = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));

    let body = {
      id: props.seletedWorkspaceDateForEdit?.id,
      name: workspaceName,
      countryCode: countryCode?.value,
      phoneNumber: phoneNumber,
      address: address,
    };
    WorkspaceRepository()
      .UpdateWorkspace(body)
      .then((res) => {
        props.exitAction(false);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getCountryList = async () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    let array = [];
    await WorkspaceRepository()
      .GetCountryList()
      .then((res) => {
        res.data.value.countries.map((item, index) => {
          return array.push({
            value: item.prePhoneNumber,
            title: item.name,
          });
        });
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
        setCountryList(array);
        if (props.hasEdit && props.seletedWorkspaceDateForEdit) {
          setCountryCode(
            array?.find(
              (country) =>
                country.value === props.seletedWorkspaceDateForEdit?.countryCode
            )
          );
        }
      });
  };
  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <div className={classes.workspaceInputBody}>
      <div className={classes.inputBox}>
        {!props.hasEdit && (
          <h1 className={classes.inputBoxTitle}>New Workspace</h1>
        )}
        <div className={classes.multiselect}>
          <SelectInput
            value={type}
            placeHolder="Select your Type"
            options={[
              { value: "0", title: "Individual" },
              { value: "1", title: "Company" },
            ]}
            handleChange={(e) => setTypeHandler(e)}
          />
        </div>
        <Input
          icon={HomeIcon()}
          placeholder="Enter Your Workspace Name"
          name="name"
          value={workspaceName}
          handleChange={(e) => nameHandler(e)}
        />
        <SelectInput
          value={countryCode ? countryCode : null}
          placeHolder="Select your Country"
          options={countryList}
          handleChange={(e) => countryHandler(e)}
        />

        <Input
          value={phoneNumber}
          icon={countryCode ? "+" + countryCode.value : MobileIcon()}
          placeholder="Enter Your Phone Number"
          name="phoneNumber"
          type="Number"
          handleChange={(e) => phoneNumberHandler(e)}
        />
        {type && type?.value === "1" && (
          <Input
            value={address}
            icon={HouseIcon()}
            placeholder="Write Your Address (Optional)"
            name="Address"
            handleChange={(e) => addressHandler(e)}
          />
        )}
      </div>

      <BaseButton
        size="lg"
        title={props.hasEdit ? "Edit" : "Create"}
        color="secondary"
        hasRightIcon={true}
        hasBlock={false}
        onClickHandler={(e) => {
          props.hasEdit ? updateWorkspace() : createWorkspace();
        }}
      />
      {!props.hasEdit && (
        <div className={classes.backLink} onClick={(e) => openWorkspaceList()}>
          Back to my Workspaces
        </div>
      )}
    </div>
  );
};
export default WorkspaceForm;
