/**
 * <ProLicenseCard
 * productName="condeinone"
 * planType="pro"
 * creationDate="2022.11.06"
 * expireDate="2023.06.15"
 * workspaceName="WingsTech"
 * />
 */

import classes from "./style/ProLicenseCard.module.scss";
import { Messages1, Calendar } from "iconsax-react";
import BaseButton from "./BaseButton";
import { useEffect, useState } from "react";

const ProLicenseCard = (props) => {
  const [expireDate, setExpireDate] = useState();
  const [expireTime, setExpireTime] = useState();
  useEffect(() => {
    setExpireDate(new Date(props.expireDate).toLocaleDateString());
    setExpireTime(new Date(props.expireDate).toLocaleTimeString());
  });
  return (
    <div className={classes.cardLayer}>
      <div className={classes.chatBox}>
        <div className={classes.chatIconBox}>
          <Messages1 className={classes.chatIcon} />
        </div>
        <h1 className={classes.chatTitle}>{props.productName}</h1>
      </div>
      <div className={classes.planType}>
        <span className={classes.planTitle}>{props.planType}</span>
      </div>
      <div className={classes.licenseDate}>
        <div className={classes.dateIconBox}>
          <Calendar className={classes.dateIcon} />
        </div>
        <span className={classes.dateText}>
          expired {expireDate} - {expireTime}
        </span>
      </div>
      <div className={classes.workspace}>
        <span className={classes.workspaceName}>WingsTech </span>
        <span className={classes.workspaceTail}>{props.workspaceName}</span>
      </div>

      <BaseButton
        size="lg"
        title="Update"
        color="secondary"
        hasBlock={true}
        onClickHandler={props.clickHandler}
      />
    </div>
  );
};
export default ProLicenseCard;
