import classes from "./style/ErrorHandling.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Warning2 } from "iconsax-react";
import { useEffect } from "react";
import { authActions } from "../../modules/auth/store/auth-slice";
const ErrorHandling = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error.error);
  useEffect(() => {
    if (error?.statusCode === 401) {
      dispatch(authActions.logOut());
    }
  }, [error]);
  return (
    <>
      {error && error.length > 0 && (
        <div className={classes.errorBox}>
          <Warning2 className={classes.errorIcon} />
          <span className={classes.errorText}>
            {error.map((err, index) => {
              return <div key={index}>{err.Message}</div>;
            })}
          </span>
        </div>
      )}
    </>
  );
};
export default ErrorHandling;
