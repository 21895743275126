import classes from "./style/Welcome.module.scss";
import welcome from "../../../assets/image/welcome.png";
import Highlight from "../../../assets/image/icon/HighlightBlack.svg";
import BaseButton from "../../../components/base/BaseButton";
import { useSelector } from "react-redux";
const Welcome = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);
  return (
    <div className={classes.welcomeSection}>
      <div className={classes.welcomeBox}>
        <div className={classes.content}>
          <div className={classes.titleSection}>
            <div className={classes.title}>
              <span className={classes.titleText}>Hello </span>

              <span className={classes.coloredText}>
                {userInfo ? userInfo.nickname : ""}!
              </span>
            </div>
            <div className={classes.HighlightImage}>
              <img src={Highlight} alt="Highlight"></img>
            </div>
          </div>
          <div className={classes.text}>
            <p>You Haven't Any Task Yet</p>
          </div>
        </div>
        <div className={classes.button}>
          <BaseButton title="Review It!" color="secondary"></BaseButton>
        </div>
      </div>
      <div className={classes.shape}></div>
      <div className={classes.welcomeImage}>
        <img src={welcome} alt="welcome"></img>
      </div>
      <div className={classes.hi}>
        <span className={classes.hiText}>
          Welcome {userInfo ? userInfo.nickname : ""}!
        </span>
      </div>
    </div>
  );
};
export default Welcome;
