import InviteUser from "../components/InviteUser";
import DashboardLayout from "../../../view/layouts/DashboardLayout";
import UserTable from "../components/UserTable";

const TeamManagement = () => {
  return (
    <DashboardLayout>
      <InviteUser />
      <UserTable />
    </DashboardLayout>
  );
};
export default TeamManagement;
