import classes from "./style/InviteUser.module.scss";
import { User, Copy } from "iconsax-react";
import BaseButton from "../../../components/base/BaseButton";
import Input from "../../../components/base/Input";
import validator from "validator";
import { useState, useEffect } from "react";
import SelectInput from "../../../components/base/SelectInput";
import { errorActions } from "../../../store/error-slice";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../../store/loading-slice";
import { teamManageActions } from "../store/teamManage-slice";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { RepoFactory } from "../../../baseRepository/Factory";
const teamManagementRepository = () => RepoFactory.get("teamManagement");
const SubscriptionRepository = () => RepoFactory.get("subscription");
const InviteUser = () => {
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const selectedSubscription = useSelector(
    (state) => state.teamManage.selectedSubscription
  );
  const hasError = useSelector((state) => state.error.hasError);
  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState("");
  const [permissionInput, setPerimissionInput] = useState(0);
  const [subscriptionInput, setSubscriptionInput] = useState(0);
  const [emailvalidat, setEmailValidate] = useState(false);
  const [error, setError] = useState(false);
  const [WorkspaceSubscription, setWorkspaceSubscription] = useState();

  const emailOnChangeHandler = (e) => {
    setEmailInput(e.target.value);
  };
  const PermissionOnChangeHandler = (e) => {
    setPerimissionInput(e.value);
  };
  const subscriptionOnChangeHandler = (e) => {
    let selected = e.value;
    dispatch(teamManageActions.setSelectedSubscription(selected));
    setSubscriptionInput(selected.id);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (validator.isEmail(emailInput)) {
        setEmailValidate(true);
        setError(false);
      } else if (emailInput.length) {
        setError(true);
      }
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [emailInput]);

  const getWorkspaceSubscription = async (subscriptionList) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(""));
    let array = [];
    if (selectedLicensedWorkspace) {
      await SubscriptionRepository()
        .getWorkspaceSubscription(selectedLicensedWorkspace.id)
        .then((res) => {
          array = res.data.value.subscription;
          setWorkspaceSubscription(array);
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    }
  };
  const inventionHandler = async () => {
    if (emailvalidat && permissionInput !== null) {
      let body = {
        roleType: parseInt(permissionInput, 10),
        email: emailInput,
        subscriptionId: subscriptionInput,
      };
      dispatch(loadingActions.setHasLoading(false));
      dispatch(loadingActions.setHasLoading(true));
      await teamManagementRepository()
        .setUserPermission(body)
        .then((res) => {
          getUserList();
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data.Reasons));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(errorActions.setHasError(true));
      if (permissionInput === null)
        dispatch(errorActions.setError("Please Select a Permission"));
      if (emailvalidat === false)
        dispatch(errorActions.setError("Email is not validate"));
    }
  };
  const getUserList = async () => {
    if (selectedSubscription) {
      dispatch(loadingActions.setHasLoading(true));
      dispatch(errorActions.setHasError(false));
      await teamManagementRepository()
        .getTeamMembers(selectedSubscription.id)
        .then((res) => {
          dispatch(
            teamManageActions.setSubscriptionUsers(res.data.value.members)
          );
        })
        .catch((error) => {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        })
        .finally(() => {
          dispatch(loadingActions.setHasLoading(false));
        });
    } else {
      dispatch(teamManageActions.setSubscriptionUsers([]));
    }
  };
  useEffect(() => {
    if (selectedLicensedWorkspace) {
      getWorkspaceSubscription();
      dispatch(teamManageActions.setSelectedSubscription(null));
    }
  }, [selectedLicensedWorkspace?.name]);
  const UserIcon = () => {
    return <User />;
  };
  const ButtonIcon = () => {
    return <Copy />;
  };
  return (
    <div className={classes.inviteLayer}>
      <div className={classes.headerBox}>
        <div className={classes.headerTitle}>
          <h1 className={classes.headerText}>Invite New User</h1>
        </div>
        <span className={classes.headerCaption}>
          You can invite up to 4 users in the form. so please input users email
          address.
        </span>
      </div>
      <div className={classes.inviteBox}>
        <div className={classes.emailContainer}>
          <div className={classes.emailBox}>
            <h1 className={classes.emailBoxTitle}>Users’s Email Address</h1>
            <div className={classes.selectInput}>
              <SelectInput
                placeHolder="Select a Subscription..."
                options={
                  WorkspaceSubscription &&
                  WorkspaceSubscription.map((item, index) => {
                    return { value: item, title: item.productName };
                  })
                }
                handleChange={(e) => subscriptionOnChangeHandler(e)}
              />
            </div>
            <SelectInput
              placeHolder="Select a Permission Type..."
              options={[
                { value: 1, title: "Admin" },
                { value: 2, title: "Contributor" },
              ]}
              handleChange={(e) => PermissionOnChangeHandler(e)}
            />
            <Input
              type="email"
              placeholder="WingsTech@gmail.com"
              name="inviteEmail"
              icon={UserIcon()}
              handleChange={emailOnChangeHandler}
            />

            <span className={classes.emailCaption}>
              You can invite up to 4 users in the form. so please input users
              email address.
            </span>
          </div>
          <div className={classes.buttonBox}>
            <BaseButton
              size="lg"
              title="Invite"
              color="primary"
              onClickHandler={(e) => inventionHandler()}
            />
            <BaseButton
              size="lg"
              title="Copy Link"
              color="secondary"
              hasLeftIcon={true}
              icon={ButtonIcon()}
            />
          </div>
        </div>
      </div>
      {hasError && <ErrorHandling />}
    </div>
  );
};
export default InviteUser;
