import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/wTAdmin/Subscriptions";
const SubscriptionRepository = {
  setSubscription(body) {
    return BaseRepository.post(`${resource}`, body);
  },
  getWorkspaceSubscription(workspaceId) {
    return BaseRepository.get(
      `${resource}/GetSubscriptions?workspaceId=${workspaceId}`
    );
  },
};
export default SubscriptionRepository;
