import classes from "./style/Pricing.module.scss";
import PriceCard from "../../../components/base/PriceCard";
import ToggleText from "../../../components/base/ToggleText";
import { useState, useEffect } from "react";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import BaseButton from "../../../components/base/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RepoFactory } from "../../../baseRepository/Factory";
import Highlight from "../../../assets/image/icon/HighlightBlack.svg";
import { workspaceActions } from "../../workspace/store/workspace-slice";
import { useNavigate } from "react-router-dom";
const SubscriptionPlansRepository = () => RepoFactory.get("subscriptionPlans");
const SubscriptionRepository = () => RepoFactory.get("subscription");
const WorkspaceRepository = () => RepoFactory.get("workspace");
const Pricing = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasYearlyPeriod, setHasYearlyPeriod] = useState(false);
  const [priceCardData, setPriceCardData] = useState([]);
  const selectedWorkspace = useSelector(
    (state) => state.workspace.selectedWorkspace
  );
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const toggleChangeHandler = () => {
    setHasYearlyPeriod(!hasYearlyPeriod);
  };
  const getSubscriptionById = (id) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    SubscriptionPlansRepository()
      .getSubscriptionPlan(id)
      .then((res) => {
        let tempArray = [];
        res.data.value.subscriptionPlan.forEach((element) => {
          tempArray.push({
            ...element,
            type: element.name === "Silver" ? "recommended" : "",
            items: [
              "Unlimited guest collaborators",
              "Customizable admin role",
              "1 TB drive storage (1000 GB)",
              "Support priority",
              "White-label and custom branding",
              "White-label and custom branding",
            ],
            btnText: "Start Free Trial",
          });
        });
        setPriceCardData(tempArray);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getSubscriptionById(params.Id);
  }, [params.Id]);
  const setSubscription = (subscriptionPlanId) => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    let body = {
      subscriptionPlanId: subscriptionPlanId,
      workSpaceId: selectedWorkspace.id,
    };
    SubscriptionRepository()
      .setSubscription(body)
      .then((res) => {
        getworkspaceList();
        navigate(
          `/your-license/${selectedWorkspace.name}/${selectedWorkspace.id}`
        );
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const getworkspaceList = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    WorkspaceRepository()
      .getAllWorkspaceList()
      .then((res) => {
        dispatch(workspaceActions.setWorkspaceList(res.data.value.workSpaces));
        res.data.value.workSpaces.map((item) => {
          if (item.id === selectedWorkspace.id) {
            localStorage.setItem(
              "selectedLicensedWorkspace",
              JSON.stringify(item)
            );
            localStorage.setItem(
              "selectedWorkspaceId",
              JSON.stringify(item.id)
            );
            dispatch(workspaceActions.setSelectedLicensedWorkspace(item));
          }
        });
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.subscriptionBox}>
      <div>
        <BaseButton
          size="sm"
          title="Change Workspace"
          color="secondary"
          hasBackIcon={true}
          onClickHandler={(e) => props.backToWorkSpaceList()}
        ></BaseButton>
      </div>
      <div className={classes.headerBox}>
        <div className={classes.title}>
          <span className={classes.headerTitle}>
            <span className={classes.colorText}>Pricing </span>Plan
          </span>
          <div className={classes.HighlightImage}>
            <img src={Highlight} alt="Highlight"></img>
          </div>
        </div>
        <div className={classes.toggle}>
          <ToggleText
            items={["Monthly", "Yearly(25% Off)"]}
            changeHandler={(e) => toggleChangeHandler()}
          />
        </div>
      </div>

      <div className={classes.priceLayer}>
        {priceCardData.length &&
          priceCardData.map((card, index) => {
            if (card.billingFrequency === Number(hasYearlyPeriod))
              return (
                <PriceCard
                  billingPeriod={card.billingFrequencyName}
                  key={index}
                  name={card.name}
                  type={card.type}
                  id={card.id}
                  description={card.description}
                  price={card.price}
                  items={card.items}
                  btnText={card.btnText}
                  selectedPriceCardHandler={setSubscription}
                />
              );
          })}
      </div>
    </div>
  );
};
export default Pricing;
