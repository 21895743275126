import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../modules/auth/store/auth-slice";
import errorSlice from "./error-slice";
import workspaceSlice from "../modules/workspace/store/workspace-slice";
import productSlice from "../modules/product/store/product-slice";
import loadingSlice from "./loading-slice";
import teamManageSlice from "../modules/TeamManagement/store/teamManage-slice";
import dashboardSlice from "../modules/dashboard/store/dashboard-slice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    workspace: workspaceSlice,
    product: productSlice,
    error: errorSlice,
    loading: loadingSlice,
    teamManage: teamManageSlice,
    dashboard: dashboardSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
export default store;
