/**
 *  <BaseTable
        color="primary"
        headers={["Name", "ProductName", "Email", "Role"]}
        rows={DUMMY_DATA.map((row) => {
          return [
            <div className={classes.tableRow}>{row.Name}</div>,
            <div className={classes.tableRow}>{row.ProductName}</div>,
            <div className={classes.tableRow}>
              <Message />
              {row.Email}
            </div>,
            <div className={classes.tableRow}>{row.Role}</div>,
          ];
        })}
      />
 */
import classes from "./style/BaseTable.module.scss";
import TableCard from "./TableCard";
const BaseTable = (props) => {
  return (
    <div>
      <div
        className={
          props.color === "secondary"
            ? classes.secondarycards
            : props.color === "primary"
            ? classes.primarycards
            : classes.cards
        }
      >
        {props.data && <TableCard data={props.data} />}
      </div>

      <table className={classes.table}>
        <thead>
          <tr className={classes.headerBox}>
            {props.data &&
              props.data[0].map((item, index) => {
                return (
                  <th key={index} style={{ width: `${item.width}` }}>
                    <h1 className={classes.headerText}>{item.title}</h1>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody
          className={
            props.color === "secondary"
              ? classes.secondaryTable
              : props.color === "primary"
              ? classes.primaryTable
              : classes.table
          }
        >
          {props.data &&
            props.data.map((row, index) => {
              return (
                <tr key={index} className={classes.tableRow}>
                  {row.map((cell, cellIndex) => {
                    return (
                      <td
                        style={{ width: `${cell.width}` }}
                        key={cellIndex}
                        className={classes.rowData}
                      >
                        {cell.row}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default BaseTable;
