import { Route, Routes } from "react-router-dom";
import TeamManagement from "../pages/TeamManagement";

const TeamManagmentRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<TeamManagement />} />
    </Routes>
  );
};

export default TeamManagmentRouter;
