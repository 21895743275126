/*
 <BaseButton
    size="sm"
    title="Lrarn More"
    color='secondary'
    hasRightIcon={true}
    hasLeftIcon={false}
    hasBlock={true}
    icon={icon()}
  ></BaseButton>
*/
import { ArrowRight, ArrowLeft } from "iconsax-react";
import { useEffect, useState } from "react";
const BaseButton = (props) => {
  const [name, setName] = useState("my-btn ");
  useEffect(() => {
    classNameComputed();
  }, []);
  const classNameComputed = () => {
    let tempName = name;
    if (props.size === "sm") {
      tempName = tempName + " small-btn ";
    } else if (props.size === "lg") {
      tempName = tempName + " larg-btn ";
    } else {
      tempName = tempName + " medium-btn ";
    }
    if (props.color === "secondary") {
      tempName = tempName + " secondary-btn ";
    } else if (props.color === "tertiary") {
      tempName = tempName + " tertiary-btn ";
    } else if (props.color === "outlinePrimary") {
      tempName = tempName + " outline-primary-btn ";
    } else if (props.color === "outlineSecondary") {
      tempName = tempName + " outline-secondary-btn ";
    } else {
      tempName = tempName + " primary-btn ";
    }
    if (props.hasBlock) {
      tempName = tempName + " blockButton ";
    }
    setName(tempName);
  };
  return (
    <div className={name} onClick={props.onClickHandler}>
      {props.hasLeftIcon && props.icon ? (
        props.icon
      ) : props.hasLeftIcon ? (
        <ArrowRight></ArrowRight>
      ) : props.hasBackIcon ? (
        <ArrowLeft />
      ) : (
        ""
      )}
      <span> {props.title}</span>
      {props.hasRightIcon && props.icon ? (
        props.icon
      ) : props.hasRightIcon ? (
        <ArrowRight></ArrowRight>
      ) : (
        ""
      )}
    </div>
  );
};
export default BaseButton;
