import Router from "./router/index.js";
import { useSelector } from "react-redux";
import LoadHandling from "./core/helpers/LoadHandling.js";
import ToastHandling from "./core/helpers/ToastHandling.js";
function App() {
  const hasLoading = useSelector((state) => state.loading.hasLoading);
  const showToast = useSelector((state) => state.error.showToast);
  return (
    <>
      {showToast && <ToastHandling />}
      {hasLoading && <LoadHandling />}
      <Router />
    </>
  );
}

export default App;
