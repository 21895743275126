import classes from "./style/SignUpForm.module.scss";
import Input from "../../../components/base/Input";
import BaseButton from "../../../components/base/BaseButton";
import { Sms, User, Key } from "iconsax-react";
import BaseAuthForm from "./BaseAuthForm";
import { useState, useEffect } from "react";
import validator from "validator";
import { RepoFactory } from "../../../baseRepository/Factory";
import { errorActions } from "../../../store/error-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { loadingActions } from "../../../store/loading-slice";
const authRepository = () => RepoFactory.get("auth");
const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [repeatPasswordInput, setRepeatPasswordInput] = useState("");
  const [emailvalidat, setEmailValidate] = useState(false);
  const hasError = useSelector((state) => state.error.hasError);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    repeatPassword: false,
  });

  const inputOnChangeHandler = (field, e) => {
    switch (field) {
      case "name":
        setNameInput(e.target.value);
        break;
      case "email":
        setEmailInput(e.target.value);
        if (isValidEmail(e.target.value)) {
          setEmailValidate(true);
        }
        break;
      case "password":
        setPasswordInput(e.target.value);
        break;
      case "repeatPassword":
        setRepeatPasswordInput(e.target.value);
        break;
      default:
        break;
    }
    if (field === "repeatPassword" && e.target.value !== passwordInput) {
      setErrors({ ...errors, [field]: true });
    } else {
      setErrors({ ...errors, [field]: false });
    }
  };

  const isValidEmail = (email) => {
    if (email.length !== 0) {
      if (validator.isEmail(email)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: nameInput.length === 0,
      email: !isValidEmail(emailInput),
      password: passwordInput.length === 0,
      repeatPassword: repeatPasswordInput.length === 0,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const resetInfo = () => {
    setNameInput("");
    setEmailInput("");
    setPasswordInput("");
    setRepeatPasswordInput("");

    setErrors({
      name: false,
      email: false,
      password: false,
      repeatPassword: false,
    });
  };
  useEffect(() => {
    if (passwordInput.length) {
      if (passwordInput !== repeatPasswordInput) {
        setErrors({
          name: errors.name,
          email: errors.email,
          password: errors.password,
          repeatPassword: false,
        });
      }
    }
  }, [passwordInput]);

  const SmsIcon = () => {
    return <Sms />;
  };
  const UserIcon = () => {
    return <User />;
  };
  const KeyIcon = () => {
    return <Key />;
  };
  const openStepTwoForm = () => {
    if (!validateForm()) {
      dispatch(loadingActions.setHasLoading(false));
      return;
    }
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    let body = {
      username: emailInput,
      password: passwordInput,
      nickName: nameInput,
    };
    authRepository()
      .Signup(body)
      .then((res) => {
        if (res.data.isSuccess) {
          props.changeUserName(emailInput);
          resetInfo();
          props.handleChange(2);
        }
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  return (
    <BaseAuthForm pageType="signUp">
      <div
        className={
          emailvalidat ? classes.inputBoxAfterValidate : classes.inputBox
        }
      >
        {hasError && <ErrorHandling />}
        <Input
          icon={UserIcon()}
          placeholder="Enter Your Name"
          name="name"
          handleChange={(e) => inputOnChangeHandler("name", e)}
          invalid={errors.name}
          invalidMsg="Please Insert The Name"
        />
        <Input
          icon={SmsIcon()}
          placeholder="Enter Your Email Address"
          name="email"
          handleChange={(e) => inputOnChangeHandler("email", e)}
          invalid={errors.email}
          invalidMsg={
            emailInput.length
              ? "Please insert Correct Email"
              : "Please Insert The Email"
          }
        />
        <Input
          icon={KeyIcon()}
          placeholder="Enter Your Password"
          name="password"
          type="password"
          handleChange={(e) => inputOnChangeHandler("password", e)}
          invalid={errors.password}
          invalidMsg="Please Insert The Password"
        />
        <Input
          icon={KeyIcon()}
          placeholder="Repeat Your Password"
          name="repeatPassword"
          type="password"
          handleChange={(e) => inputOnChangeHandler("repeatPassword", e)}
          invalid={errors.repeatPassword}
          invalidMsg={
            repeatPasswordInput.length
              ? "It's Not Equal To Password"
              : "Please RepeatPassword"
          }
        />
        {emailvalidat && (
          <div className={classes.information}>
            <div className={classes.offers}>
              <input
                type="checkbox"
                id="offer"
                name="offer"
                value=""
                className="input-check-box"
              />
              <span />
              <label className={classes.offerText}>
                Yes! Send me news and offers from Atlassian about products,
                events, and more.
              </label>
            </div>
            <div className={classes.assurance}>
              <span className={classes.assuranceText}>
                By signing up, I accept the Atlassian Cloud
                <a href="/#" className={classes.link}>
                  Terms of Service
                </a>
                Terms of Service and acknowledge the
                <a href="/#" className={classes.link}>
                  Privacy Policy
                </a>
                .
              </span>
            </div>
          </div>
        )}
        <BaseButton
          size="lg"
          hasBlock={true}
          title="Continue"
          color="secondary"
          hasRightIcon={true}
          onClickHandler={openStepTwoForm}
        />
      </div>
    </BaseAuthForm>
  );
};
export default SignUpForm;
