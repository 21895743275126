import classes from "./style/ProfileEditing.js.module.scss";
import BaseButton from "../../../components/base/BaseButton";
import Input from "../../../components/base/Input";
import Avatar from "../../../assets/image/avatar.png";
import { Edit } from "iconsax-react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "../../../store/error-slice";
import { loadingActions } from "../../../store/loading-slice";
import ErrorHandling from "../../../core/helpers/ErrorHandling";
import { RepoFactory } from "../../../baseRepository/Factory";

const authRepository = () => RepoFactory.get("auth");

const ProfileEditing = () => {
  const dispatch = useDispatch();
  const hasError = useSelector((state) => state.error.hasError);
  const [userInfo, setUserInfo] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [email, setEmail] = useState("");
  const [nickname, setNickName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const EditIcon = () => {
    return <Edit />;
  };
  const getUser = () => {
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    dispatch(loadingActions.setHasLoading(true));
    authRepository()
      .getUser()
      .then((res) => {
        setUserInfo(res.data.value.user);
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    if (userInfo) {
      setCustomerId(userInfo.id);
      setEmail(userInfo.email);
      setNickName(userInfo.nickName);
      setPhoneNumber(userInfo.cellPhoneNumber);
    }
  }, [userInfo]);
  const userUpdate = () => {
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    dispatch(loadingActions.setHasLoading(true));

    let body = {
      nickName: nickname,
      cellPhoneNumber: phoneNumber,
    };
    authRepository()
      .UpdateUserInfo(body)
      .then((res) => {})
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };

  return (
    <div className={classes.editBox}>
      <div className={classes.profileTitle}>
        <div className={classes.pictureBox}>
          <span className={classes.textIcon}>
            {userInfo ? userInfo.nickName[0] : "N"}
          </span>
        </div>
      </div>

      <div className={classes.InputBox}>
        <div className={classes.input}>
          <Input
            label="Customer ID"
            type="text"
            value={customerId}
            placeholder="Enter ID"
            name="customerId"
            readOnly={true}
            handleChange={(e) => {
              setNickName(e.target.value);
            }}
          />
        </div>
        <div className={classes.input}>
          <Input
            label="Email"
            type="email"
            value={email}
            placeholder="Enter email"
            name="email"
            readOnly={true}
            handleChange={(e) => {
              setNickName(e.target.value);
            }}
          />
        </div>
        <div className={classes.input}>
          <Input
            label="Nickname"
            type="text"
            value={nickname}
            placeholder="Enter Your Name"
            name="nickName"
            handleChange={(e) => {
              setNickName(e.target.value);
            }}
          />
        </div>
        <div className={classes.input}>
          <Input
            label="Phone Number"
            type="phone"
            value={phoneNumber}
            placeholder="Enter Phone Number"
            name="phoneNumber"
            handleChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div>
        <div className={classes.action}>
          <BaseButton
            hasBlock={false}
            title="Edit Profile"
            hasLeftIcon={true}
            icon={EditIcon()}
            color="secondary"
            onClickHandler={(e) => userUpdate()}
          />
        </div>
        <div className={classes.smallAction}>
          <BaseButton
            hasBlock={false}
            title="Edit"
            hasLeftIcon={true}
            icon={EditIcon()}
            color="secondary"
            onClickHandler={(e) => userUpdate()}
          />
        </div>
        {hasError && <ErrorHandling />}
      </div>
    </div>
  );
};
export default ProfileEditing;
