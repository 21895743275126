import { useState } from "react";
import classes from "./style/Notification.module.scss";
import NotificationCard from "../../../components/base/NotificationCard";
import BaseButton from "../../../components/base/BaseButton";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const navigate = useNavigate();
  const [notification] = useState([
    {
      title: "You Haven't Got Any Notification Yet",
    },
  ]);
  return (
    <div className={classes.notification}>
      <div className={classes.header}>
        <div className={classes.titleSection}>
          <h1 className={classes.title}>
            Subscribed
            <br />
            <strong>Notifications</strong>
          </h1>
          <span className={classes.numberNotif}>0 Subscribed today</span>
        </div>
        <BaseButton
          title="See All"
          hasRightIcon={true}
          size="sm"
          color="outlinePrimary"
          onClickHandler={(e) => navigate("/notification")}
        ></BaseButton>
      </div>
      {notification.map((notif, index) => {
        return (
          <NotificationCard key={index} title={notif.title} time={notif.time} />
        );
      })}
    </div>
  );
};
export default Notification;
