import classes from "./style/BaseAuthForm.module.scss";
import Logo from "../../../assets/image/logo/blueLogo.svg";
import Ellipse from "../../../assets/image/icon/Ellipse-gray.svg";
import TextButton from "../../../components/base/TextButton";
import { useNavigate } from "react-router-dom";
const BaseAuthForm = (props) => {
  const navigate = useNavigate();
  const goToNextPage = async () => {
    if (props.pageType === "signUp") {
      navigate("/auth/login");
    } else if (props.pageType === "login") {
      navigate("/auth/signup");
    } else if (props.pageType === "forget-password") {
      navigate("/auth/login");
    }
  };
  return (
    <div className={classes.body}>
      <div className={classes.logo}>
        <img className={classes.logo} src={Logo} alt="Logo" />
      </div>
      <div className={classes.formSection}>
        <div className={classes.form}>{props.children}</div>
        <div className={classes.signup}>
          {(props.pageType === "signUp" ||
            props.pageType === "forget-password") && (
            <>
              <h5 className={classes.quesion}> Have an Account?</h5>
              <TextButton
                size="lg"
                title="Log In"
                color="primary"
                onClickHandler={goToNextPage}
              ></TextButton>
            </>
          )}

          {props.pageType === "login" && (
            <>
              <TextButton
                size="lg"
                title="Forget My Password"
                color="primary"
                onClickHandler={(e) => {
                  navigate("/auth/forget-password");
                }}
              />
              <h5 className={classes.quesion}>Don’t Have an Account?</h5>
              <TextButton
                size="lg"
                title="Sign UP"
                color="primary"
                onClickHandler={goToNextPage}
              ></TextButton>
            </>
          )}
        </div>
      </div>

      <div className={classes.option}>
        <h3 className={classes.text}>Privacy Policy</h3>
        <img className={classes.ellipse} src={Ellipse} alt="ellipse" />
        <h3 className={classes.text}>User Notice</h3>
      </div>
    </div>
  );
};
export default BaseAuthForm;
