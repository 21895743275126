import classes from "./style/BaseAlert.module.scss";
import { Warning2 } from "iconsax-react";
const BaseAlert = (props) => {
  const AlertStyle = () => {
    if (props.type === "warning") return classes.warningStyle;
  };
  const AlertIcon = () => {
    if (props.type === "warning")
      return <Warning2 className={classes.headerIcon} />;
  };
  return (
    <div className={`${classes.alertBox} ${AlertStyle()}`}>
      <div className={classes.alertHeader}>
        {AlertIcon()}
        <h1 className={classes.headerText}>{props.type}</h1>
      </div>
      <span className={classes.alertDescription}>{props.description}</span>
    </div>
  );
};
export default BaseAlert;
