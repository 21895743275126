import classes from "./style/Profile.module.scss";
import ProfileEditing from "../components/ProfileEditing";
import DashboardLayout from "../../../view/layouts/DashboardLayout";
const Profile = () => {
  return (
    <DashboardLayout>
      <div className={classes.ProfileLayer}>
        <div className={classes.titleBox}>
          <div className={classes.title}>
            <h1 className={classes.titleText}>
              Complete Your <span className={classes.coloredText}>Profile</span>
            </h1>
          </div>
        </div>
        <div>
          <ProfileEditing />
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Profile;
