import BaseRepository from "../../../baseRepository/BaseRepository";
const resource = "/api/v1/wTAdmin/Users";

const AuthRepository = {
  Signup(body) {
    return BaseRepository.post(`${resource}/Signup`, body);
  },
  VerifyEmail(body) {
    return BaseRepository.post(`${resource}/VerifyEmail`, body);
  },
  Signin(body) {
    return BaseRepository.post(`${resource}/Signin`, body);
  },
  ResendVerificationCode(body) {
    return BaseRepository.post(`${resource}/ResendVerificationCode`, body);
  },
  ForgetPassword(body) {
    return BaseRepository.post(`${resource}/ForgetPassword`, body);
  },
  ResetPassword(body) {
    return BaseRepository.post(`${resource}/ResetPassword`, body);
  },
  UpdateUserInfo(body) {
    return BaseRepository.put(`${resource}`, body);
  },
  getUser() {
    return BaseRepository.get(`${resource}`);
  },
  changePassword(body) {
    return BaseRepository.post(`${resource}/ChangePassword`, body);
  },
};
export default AuthRepository;
