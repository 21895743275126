/**
 *    <FAQ
        question="Will you price gouge if I need additional work?"
        answer="Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages.Our unique approach to the diagnosis and treatment of adult ADHD can turn challenges into entrepreneurial advantages."
      ></FAQ>
 */
import { useState } from "react";
import classes from "./style/FAQ.module.scss";
import { Add, Minus } from "iconsax-react";
const FAQ = (props) => {
  const [openAnswer, setOpenAnswer] = useState(false);
  const changeOpenAnswerHandler = () => {
    setOpenAnswer(!openAnswer);
  };
  return (
    <div className={classes.FAQ}>
      {!openAnswer && (
        <div
          className={classes.closeAnswer}
          onClick={(e) => changeOpenAnswerHandler()}
        >
          <div className={classes.question}>
            <h2>{props.question}</h2>
          </div>
          <div className={classes.addBtn}>
            <Add className={classes.icon} />
          </div>
        </div>
      )}
      {openAnswer && (
        <div
          className={classes.openAnswer}
          onClick={(e) => changeOpenAnswerHandler()}
        >
          <div className={classes.qBox}>
            <div className={classes.question}>
              <h2>{props.question}</h2>
            </div>
            <div className={classes.MinusBtn}>
              <Minus className={classes.icon} />
            </div>
          </div>
          <div className={classes.answer}>
            <p>{props.answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default FAQ;
