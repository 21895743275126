import classes from "./style/Header.module.scss";
import IconButton from "../base/IconButton";
import HeaderDropDown from "./HeaderDropDown";
import { useDispatch, useSelector } from "react-redux";
import { NotificationBing, ArrowDown2, ArrowUp2, Home } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { errorActions } from "../../store/error-slice";
import { loadingActions } from "../../store/loading-slice";
import { workspaceActions } from "../../modules/workspace/store/workspace-slice";
import { useEffect, useState, useRef } from "react";
import { RepoFactory } from "../../baseRepository/Factory";
const WorkspaceRepository = () => RepoFactory.get("workspace");
const Header = () => {
  const ref = useRef(null);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const workspaceList = useSelector((state) => state.workspace.workspaceList);
  const selectedLicensedWorkspace = useSelector(
    (state) => state.workspace.selectedLicensedWorkspace
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);
  const [workspaceTitle, setWorkspaceTitle] = useState("WorkspaceList");

  const dropDownExitHandler = (status) => {
    setShowDropDown(status);
  };
  const getworkspaceList = () => {
    dispatch(loadingActions.setHasLoading(true));
    dispatch(errorActions.setHasError(false));
    dispatch(errorActions.setError(null));
    WorkspaceRepository()
      .getAllWorkspaceList()
      .then((res) => {
        dispatch(workspaceActions.setWorkspaceList(res.data.value.workSpaces));
      })
      .catch((error) => {
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError(error.response?.data.Reasons));
      })
      .finally(() => {
        dispatch(loadingActions.setHasLoading(false));
      });
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  useEffect(() => {
    getworkspaceList();
    if (workspaceList && workspaceList.length) {
      if (localStorage.getItem("selectedLicensedWorkspace")) {
        let licenseWorkspace = JSON.parse(
          localStorage.getItem("selectedLicensedWorkspace")
        );
        if (workspaceList.find((item) => item?.id === licenseWorkspace?.id)) {
          dispatch(
            workspaceActions.setSelectedLicensedWorkspace(licenseWorkspace)
          );
        } else {
          localStorage.removeItem("selectedLicensedWorkspace");
          localStorage.removeItem("selectedWorkspaceId");
        }
      } else {
        dispatch(
          workspaceActions.setSelectedLicensedWorkspace(workspaceList[0])
        );
        localStorage.setItem(
          "selectedLicensedWorkspace",
          JSON.stringify(workspaceList[0])
        );
        localStorage.setItem(
          "selectedWorkspaceId",
          JSON.stringify(workspaceList[0].id)
        );
      }
    }
    if (workspaceList.length === 0) {
      // localStorage.removeItem("selectedLicensedWorkspace");
      // localStorage.removeItem("selectedWorkspaceId");
      dispatch(workspaceActions.setSelectedLicensedWorkspace(null));
      setWorkspaceTitle("New Workspace");
    }
  }, [workspaceList?.length]);
  useEffect(() => {
    if (!selectedLicensedWorkspace) {
      setWorkspaceTitle("New Workspace");
    }
  }, [selectedLicensedWorkspace]);

  const NotificationBingIcon = () => {
    return <NotificationBing />;
  };
  const HomeIcon = () => {
    return <Home />;
  };
  return (
    <div className={classes.header}>
      <IconButton
        size="lg"
        icon={HomeIcon()}
        onClickHandler={(e) => navigate("/")}
      ></IconButton>
      <IconButton
        size="lg"
        icon={NotificationBingIcon()}
        onClickHandler={(e) => navigate("/notification")}
      ></IconButton>

      <div className={classes.profile}>
        <div className={classes.avatar}>
          <span className={classes.textIcon}>
            {userInfo ? userInfo.nickname[0] : "N"}
          </span>
        </div>
        <div
          className={classes.name}
          onClick={(e) => setShowDropDown(!showDropDown)}
        >
          {selectedLicensedWorkspace
            ? selectedLicensedWorkspace.name
            : workspaceTitle}

          <div className={classes.icon}>
            {showDropDown === false && <ArrowDown2 />}
            {showDropDown === true && <ArrowUp2 />}
          </div>
        </div>
        {showDropDown && (
          <div className={classes.dropdown} ref={ref}>
            <HeaderDropDown exitAction={dropDownExitHandler} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
